const CreatorSection = () => {
    return (
        <div className="grid pt-8 text-left md:gap-16 dark:border-gray-700 md:grid-cols-2">
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        How do I start earning on ONLYSXY?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        As soon as you have activated your creator account and published some paid content you can start earning. Everytime someone pays for your content you will receive the money instantly in your ONLYSXY wallet. You can then withdraw the money to your own wallet or exchange it for your local currency. Anytime you want, without minimum amounts or waiting times. Even more, if you activate autoPay you will get the money instantly on your own wallet everytime someone pays for your content.
                    </p>
                </div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        How can I activate autoPay?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        You just need to go to your dashboard and enter a Lightning Address. As soon as you save the changes all the funds on your ONLYSXY wallet will be transferred to your Lightning Address. From that moment on, all your payments will be directly routed to this Lightnig Address. If you remove the Lightnig Address from your dashboard, autoPay will be automatically disabled.
                    </p>
                </div>
            </div>
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        What is a Lightning Address?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">A Lightnig Address looks like an email addres (you@your-wallet.com) and it's just an user-friendly way to receive Bitcoin on your Lightning wallet. You will usually find your Lightning Address on the "Receive" section of your wallet.</p>
                    <a
                        href="https://lightningaddress.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Learn more about Lightning Addresses{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CreatorSection;