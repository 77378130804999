import { useState, useEffect, useRef, memo, useMemo, useCallback } from 'react';

let counter = 0;

function VideoPlayer({ video, aspectRatio, maxWidth }) {

  const id = `video-container-${counter++}`;
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [transcodingComplete, setTranscodingComplete] = useState(false);
  const [loaded, setLoaded] = useState(true); //don't use image preview for the moment

  // Memoize videoStatus and videoUrl
  const videoStatus = useMemo(() => video.status, [video]);
  const videoUrl = useMemo(() => video.url, [video]);
  const interval = useMemo(() => {
    if (video.length === 0) {
      return 1000; 
    } else {
      return video.length * 10; // Calculate interval based on video length
    }
  }, [video]);
  const thumbnailUrl = useMemo(() => video.thumbnail, [video]);
  const previewUrl = useMemo(() => video.preview, [video]);

  useEffect(() => {
    setProgress(video.progress)
  }, [video])

  useEffect(() => {
    if (transcodingComplete || videoStatus === 4) {

      const container = document.getElementById(id);
      const videoElement = container.querySelector('iframe');

      if (!videoElement) return;

      // Initialize the intersection observer
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          /*
          if (!entry.isIntersecting) {
            const videoPlayer = videoElement.contentWindow.document.querySelector('video');
            if (!videoPlayer) return;
            // If the video is not intersecting with the viewport, pause it
            // TODO. how to pause embedded video?
            //videoPlayer.pause();
          }
          */
        });
      });

      // Observe the video element
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }

  }, [id, transcodingComplete]);

  useEffect(() => {
    let progressInterval;
    if (videoStatus !== 4) {
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 1;
          if (nextProgress === 100) {
            clearInterval(progressInterval);
            setTranscodingComplete(true);
          }
          return nextProgress;
        });
      }, interval);
    }
    return () => {
      clearInterval(progressInterval);
    };
  }, [videoStatus]);

  const handleMouseEnter = useCallback(() => {
    if (!loaded) {
      videoRef.current.src = previewUrl;
    }
  }, [loaded, previewUrl]);

  const handleMouseLeave = useCallback(() => {
    if (!loaded) {
      videoRef.current.src = thumbnailUrl;
    }
  }, [loaded, thumbnailUrl]);

  const handleClick = useCallback(() => {
    setLoaded(true);
    videoRef.current.src = `${videoUrl}&autoplay=false&preload=false`;
  }, [videoUrl]);

  return (
    <div className='videoPlayer-container'>
      {videoStatus !== 4 && !transcodingComplete && (
        <>
          <div className='videoPlayer-progress-container'>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
            </div>
            Hang on, your video will be ready soon!
          </div>
        </>
      )}
      {transcodingComplete || videoStatus === 4 ? (
        <div id={id} className='flex justify-center'>
          {!loaded && (
            <img
              ref={videoRef}
              src={thumbnailUrl}
              className='videoPlayer-image'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
              style={loaded ? { display: 'none' } : {}}
            />
          )}
          {loaded && (
            <iframe
              src={`${videoUrl}&autoplay=false&preload=false`}
              style={{
                aspectRatio: `${aspectRatio}`,
                width: '100%',
                maxHeight: '90vh',
                maxWidth: `${(100 * aspectRatio) * 0.9}vh`
              }}
              loading="lazy"
              allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
              allowFullScreen
            />
          )}
        </div>
      ) : (
        null
      )
      }
    </div>
  );
}

export default memo(VideoPlayer);