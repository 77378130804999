import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "services/auth.service";

export default function Register({ redirect }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [conditions, setConditions] = useState(false);
  const [ref, setRef] = useState(localStorage.getItem("ref"));
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [roleSwitch, setRoleSwitch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serverSuccess = queryParams.get('success');

  useEffect(() => {
    if (serverSuccess === "false") {
      setVerifyError(true);
    }
  }, [serverSuccess]);

  const handleToggle = () => {
    setRoleSwitch(!roleSwitch);
  };

  useEffect(() => {
    if (roleSwitch) {
      setRole("creator");
    } else {
      setRole("user");
    }
  }, [roleSwitch]);

  const USERNAME_REGEX = /^[a-zA-Z0-9_-]{3,16}$/;
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d,.\-_!?]{8,}$/;

  const handleRegister = (e) => {
    e.preventDefault();

    setVerifyError(false);

    // Perform input validation
    if (!email || !password || !username || !role || !conditions) {
      let errorMessage = "Please enter all fields.";
      setErrorMessage(errorMessage);
      return;
    }

    if (!username.match(USERNAME_REGEX)) {
      let errorMessage = "Username should contain only letters, numbers, hyphens (-), and underscores (_) and be between 3 and 16 characters long.";
      setUsernameError(errorMessage);
      return;
    }

    if (!email.match(EMAIL_REGEX)) {
      let errorMessage = "Please enter a valid email address.";
      setEmailError(errorMessage);
      return;
    }

    if (!password.match(PASSWORD_REGEX)) {
      let errorMessage = "Password doesn't meet the following criteria:";

      if (password.length < 8) {
        errorMessage += "\n- At least 8 characters in length";
      }

      if (!/(?=.*[A-Za-z])/.test(password)) {
        errorMessage += "\n- Include at least one letter";
      }

      if (!/(?=.*\d)/.test(password)) {
        errorMessage += "\n- Include at least one digit";
      }

      if (!/^[A-Za-z\d,.\-_!?]+$/.test(password)) {
        errorMessage += "\n- Use only valid symbols (, . - _ ! ?)";
      }

      setErrorMessage(errorMessage);
      return;
    }

    // Clear any previous error message
    setErrorMessage("");

    AuthService.register(username, email, password, role, ref)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setErrorMessage(resMessage);
      });
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex flex-col content-center items-center justify-center h-full">
          {success && (
            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
              <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-check"></i>
              </span>
              <span className="inline-block align-middle mr-8">
                <b className="capitalize">Check your email to confirm your account.</b>
              </span>
            </div>
          )}
          {verifyError && (
            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
              <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-exclamation"></i>
              </span>
              <span className="inline-block align-middle mr-8">
                <b className="capitalize">Something went wrong. Please register again.</b>
              </span>
            </div>
          )}
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 ">
              <form>
                <div className="rounded-t mb-3 px-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Join as
                    </h6>
                  </div>
                  <div className="text-center flex justify-center">
                    <label className="flex items-center justify-around w-full">
                      <span
                        className={`w-full pr-12 text-right font-medium ${!roleSwitch
                          ? "font-bold text-xl"
                          : "text-base"
                          } text-gray-900 dark:text-gray-300"`}
                      >
                        Fan
                      </span>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                        className="relative inline-flex items-center cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                          checked={roleSwitch}
                          onChange={handleToggle}
                        />
                        <div
                          className={`w-11 h-6 bg-blue-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-pink-500`}
                        ></div>
                      </div>
                      <span
                        className={`w-full text-left pl-12 font-medium ${roleSwitch ? "font-bold text-xl" : "text-base"
                          } text-gray-900 dark:text-gray-300`}
                      >
                        Creator
                      </span>
                    </label>
                  </div>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${usernameError && !username.match(USERNAME_REGEX)
                      ? "border-red-500"
                      : ""
                      }`}
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={() => {
                      if (!username.match(USERNAME_REGEX)) {
                        setUsernameError(
                          "Username should contain only letters, numbers, and underscores and be between 3 and 16 characters long."
                        );
                      } else {
                        setUsernameError("");
                      }
                    }}
                  />
                  {usernameError && !username.match(USERNAME_REGEX) && (
                    <div className="text-red-500 text-sm mt-1">
                      {usernameError}
                    </div>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${emailError && !email.match(EMAIL_REGEX)
                      ? "border-red-500"
                      : ""
                      }`}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => {
                      if (!email.match(EMAIL_REGEX)) {
                        setEmailError("Please enter a valid email address.");
                      } else {
                        setEmailError("");
                      }
                    }}
                  />
                  {emailError && !email.match(EMAIL_REGEX) && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailError}
                    </div>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorMessage && !password.match(PASSWORD_REGEX)
                      ? "border-red-500"
                      : ""
                      }`}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => {
                      if (!password.match(PASSWORD_REGEX)) {
                        let errorMessage = "Password doesn't meet the following criteria:";

                        if (password.length < 8) {
                          errorMessage += "\n- At least 8 characters in length";
                        }

                        if (!/(?=.*[A-Za-z])/.test(password)) {
                          errorMessage += "\n- Include at least one letter";
                        }

                        if (!/(?=.*\d)/.test(password)) {
                          errorMessage += "\n- Include at least one digit";
                        }

                        if (!/^[A-Za-z\d,.\-_!?]+$/.test(password)) {
                          errorMessage += "\n- Use only valid symbols (, . - _ ! ?)";
                        }

                        setPasswordError(errorMessage);
                        return;
                      } else {
                        setPasswordError("");
                      }
                    }}
                  />
                  {passwordError && !password.match(PASSWORD_REGEX) && (
                    <div className="text-red-500 text-sm mt-1">
                      {passwordError.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>

                <div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin"
                      type="checkbox"
                      className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      checked={conditions}
                      onChange={(e) => setConditions(e.target.checked)}
                    />
                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                      I agree with the{" "}
                      <a
                        href="/terms"
                        target="_blank"
                        rel="noreferrer"
                        className="text-sky-500"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and the{" "}
                      <a
                        href="/privacy"
                        target="_blank"
                        rel="noreferrer"
                        className="text-sky-500"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>

                <div className="text-center mt-6">
                  <button
                    className={`bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 ${!conditions
                      ? "opacity-50 cursor-default"
                      : "hover:shadow-lg"
                      }`}
                    type="button"
                    disabled={!conditions}
                    style={{
                      pointerEvents: !conditions ? "none" : "auto",
                    }}
                    onClick={handleRegister}
                  >
                    Create Account
                  </button>
                </div>

                {/* Error message */}
                {errorMessage && (
                  <div className="text-red-500 text-sm mb-4 text-center">
                    {errorMessage.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
