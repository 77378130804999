import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import authService from "services/auth.service";

export default function Login({ redirect }) {

  const altLogin = false; //hide alternative login methods

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [remember, setRemember] = useState(false);
  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const serverSuccess = queryParams.get('success');

  useEffect(() => {
    if (serverSuccess === 'true') {
      setSuccess(true);
    }
  }, [serverSuccess]);


  const handleLogin = (e, uname, pass) => {
    e.preventDefault();

    // Perform input validation
    if ((!username && !uname) || (!password && !pass)) {
      setErrorMessage("Please enter both username and password.");
      return;
    }

    // Clear any previous error message
    setErrorMessage("");

    authService.login(username || uname, password || pass, remember).then(
      () => {
        if (redirect) {
          navigate('/' + redirect);
        } else {
          navigate('/dashboard');
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setErrorMessage(resMessage);
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Press Enter key on the password field triggers the login button
      e.preventDefault();
      handleLogin(e, usernameInputRef.current.value, passwordInputRef.current.value);
    }
  };

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (passwordInputRef.current) {
        passwordInputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex flex-col content-center items-center justify-center h-full">
          {success &&
            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
              <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-check"></i>
              </span>
              <span className="inline-block align-middle mr-8">
                <b className="capitalize">Account activated.</b>
              </span>
            </div>
          }
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            {altLogin &&
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Log in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg").default}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
            }
            <div className="flex-auto px-4 lg:px-10 py-10">
              <form>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    ref={usernameInputRef}
                    type="text"
                    id="username"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    ref={passwordInputRef}
                    type="password"
                    id="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin"
                      type="checkbox"
                      className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                    />
                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                      Remember me
                    </span>
                  </label>
                </div>

                <div className="text-center mt-6">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleLogin}
                  >
                    Log In
                  </button>
                </div>
                <div className="text-center mt-3">
                  <Link to="/auth/register" className="text-blueGray-600">
                    <small>Create new account</small>
                  </Link>
                </div>
                {/* Error message */}
                {errorMessage && (
                  <div className="text-red-500 text-sm mb-4 text-center">{errorMessage}</div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
