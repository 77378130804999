import React, { useState } from 'react';
import BitcoinSection from './BitcoinSection';
import CreatorSection from './CreatorSection';
import FanSection from './FanSection';

const FaqSection = () => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (tabNumber) => {
    setActiveTab(tabNumber === activeTab ? null : tabNumber);
  };

  return (
    <div>
      <div className="accordion">
        <h2 className="accordion-heading">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => toggleTab(1)}
          >
            <span>About Bitcoin</span>
            <svg
              className={`w-3 h-3 shrink-0 ${activeTab === 1 ? '' : 'rotate-180'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M9 5 5 1 1 5"></path>
            </svg>
          </button>
        </h2>
        {activeTab === 1 && (
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <BitcoinSection />
          </div>
        )}
        <h2 className="accordion-heading">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => toggleTab(2)}
          >
            <span>For Creators</span>
            <svg
              className={`w-3 h-3 shrink-0 ${activeTab === 2 ? '' : 'rotate-180'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M9 5 5 1 1 5"></path>
            </svg>
          </button>
        </h2>
        {activeTab === 2 && (
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
            <CreatorSection />
          </div>
        )}
        <h2 className="accordion-heading">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 ${activeTab === 3 ? '' : 'rounded-b-xl'}`}
            onClick={() => toggleTab(3)}
          >
            <span>For Fans</span>
            <svg
              className={`w-3 h-3 shrink-0 ${activeTab === 3 ? '' : 'rotate-180'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M9 5 5 1 1 5"></path>
            </svg>
          </button>
        </h2>
        {activeTab === 3 && (
          <div className={`p-5 border border-t-0 border-gray-200 dark:border-gray-700 ${activeTab === 3 ? 'rounded-b-xl' : ''}`}>
            <FanSection />
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqSection;