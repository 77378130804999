import React, { useState, useEffect } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";
import PrivacyMD from 'assets/txt/privacy.md';
import Markdown from 'markdown-to-jsx'

export default function Privacy() {

    const [privacyText, setPrivacyText] = useState("");

    useEffect(() => {
        fetch(PrivacyMD)
            .then(res => res.text())
            .then(text => setPrivacyText(text));
    }, []);

    return (
        <>
            <AdminNavbar fixed />
            <section className="bg-white dark:bg-gray-900">
                <div className="py-24 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-3xl tracking-tight font-semibold text-gray-900 dark:text-white">
                            Privacy Policy
                        </h2>
                        <p className="mb-4 font-medium">Last Update: August 15, 2023</p>
                        <div className="mb-4">
                            <Markdown>{privacyText}</Markdown>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}