import api from "./api";
import TokenService from "./token.service";

const URL_PREFIX = '/auth/';

class AuthService {
  async login(username, password, remember) {
    const response = await api
      .post(URL_PREFIX + "signin", {
        username,
        password
      });
    if (response.data.accessToken) {
      TokenService.setUser(response.data, remember);
    }
    return response.data;
  }

  async checkUser() {
    const response = await api
      .get(URL_PREFIX + "check");
    return response.status === 200;
  }

  logout() {
    TokenService.removeUser();
    window.location.replace('/auth/login');
  }

  register(username, email, password, role, ref) {
    const roles = [role];
    return api.post(URL_PREFIX + "signup", {
      username,
      email,
      password,
      roles,
      ref,
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;