const BitcoinSection = () => {
    return (
        <div className="grid pt-8 text-left md:gap-16 dark:border-gray-700 md:grid-cols-2">
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        What is Bitcoin?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        Bitcoin is a distributed peer-to-peer digital currency that can be transferred instantly and securely between any two people in the world. It's like electronic cash that you can use to pay friends or merchants. There are hundreds of websites where you can learn more about Bitcoin.
                    </p>
                    <a
                        href="https://en.bitcoin.it/wiki/Help:FAQ"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Bitcoin FAQs{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                    <br />
                    <a
                        href="https://bitcoin.rocks"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Why is Bitcoin useful?{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                    <br />
                    <a
                        href="https://www.lopp.net/bitcoin-information/getting-started.html"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Getting started with Bitcoin{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                </div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        What is Bitcoin Lightning?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        Bitcoin Lightning is a second-layer scaling solution for the Bitcoin blockchain. It creates off-chain payment channels between users, enabling fast, low-cost transactions without overloading the main blockchain. This improves scalability and reduces transaction fees, making it ideal for everyday purchases and microtransactions. You can learn more about Bitcoin Lightning here:
                    </p>
                    <a
                        href="https://lightning.network"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Lightning Network{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                    <br />
                    <a
                        href="https://www.santander.com/en/stories/lightning-network-blockchain"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Santander on Bitcoin Lightning{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                </div>
            </div>
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        Why do you only accept Bitcoin?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        At our platform, we wholeheartedly support Bitcoin as the sole payment method for several compelling reasons. One of the key advantages is the ability to achieve instant settlements, enabling us to offer lower transaction fees compared to traditional payment systems like Visa and Mastercard. Moreover, unlike Visa and Mastercard, Bitcoin allows us to prioritize user privacy without being bound by strict anti-privacy rules. By embracing Bitcoin's decentralized and secure nature, we aim to foster a community that values financial freedom and privacy while providing a seamless and empowering experience for all creators and fans on our platform.
                    </p>
                </div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        What are "sats"?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        "Sats" is a colloquial term for "satoshis," which is the smallest unit of Bitcoin. One Bitcoin is divisible into 100 million satoshis. It is named after Satoshi Nakamoto, the pseudonymous creator of Bitcoin. Sats are commonly used to express smaller Bitcoin amounts, especially when dealing with fractions of a Bitcoin or conducting microtransactions. For example, instead of saying "0.001 Bitcoin," someone might say "100,000 sats" to refer to the same amount.
                    </p>
                    <a
                        href="https://satsconverter.io"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Sats Converter{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default BitcoinSection;