import NoDataImage from 'assets/svg/undraw_no_data_re_kwbl.svg'
import BalloonsImage from 'assets/svg/undraw_floating_re_xtcj.svg'

const NoData = ({ text }) => {
    return (
        <div className="flex flex-col items-center justify-center text-center min-h-screen px-12">
            <h3
                className='font-bold text-2xl text-gray-700'>
                {text}
            </h3>
            <img
                className="pt-10 w-6/12 max-w-300-px"
                src={BalloonsImage}
                alt="No Data Found"
            />
        </div>
    )
}

export default NoData;