import React, { useState, useEffect } from 'react';
import platformOptions from 'assets/platforms/PlatformInfo.json'

const Comparer = () => {
    const [earnings, setEarnings] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [newEarnings, setNewEarnings] = useState(null);
    const [savings, setSavings] = useState(null);
    const [earningsDelta, setEarningsDelta] = useState(null);
    const [fees, setFees] = useState(null);

    const handleEarningsChange = (event) => {
        const inputValue = event.target.value;
        // Regular expression to match the format "0.00"
        const regex = /^\d+(\.\d{0,2})?$/;

        if (regex.test(inputValue) || inputValue === '') {
            setEarnings(inputValue);
        }
    };

    const handlePlatformChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedPlatform(selectedValue);

        // Find the selected platform object from the JSON data
        const selectedPlatformObject = platformOptions.find(
            (option) => option.name === selectedValue
        );

        // Update the fees state with the selected platform's fees
        if (selectedPlatformObject) {
            setFees(selectedPlatformObject.fees);
        } else {
            setFees(null); // Reset fees if no platform is selected
        }
    }

    useEffect(() => {
        if (selectedPlatform !== "" && earnings !== null && fees !== null) {
            const grossEarnings = earnings / (1 - fees);
            const newEarnings = grossEarnings * 0.95;
            setNewEarnings('$' + newEarnings.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
            setSavings((((newEarnings / earnings) - 1) * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
            setEarningsDelta((newEarnings - earnings).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        }
    }, [selectedPlatform, earnings, fees]);

    return (
        <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg py-12 px-6 relative z-10">
            <div className="w-full text-center">
                <div className="flex flex-col">
                    <span className='text-base text-blueGray-500 pb-4'>
                        Your current monthly earnings
                    </span>
                    <div className='flex flex-col md:flex-row items-center justify-evenly'>
                        <div className="relative w-full md:w-6/12">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="w-5 h-5 text-gray-500 dark:text-gray-400 fas fa-dollar-sign flex items-center jusitfy-center"></i> { }
                            </div>
                            <input
                                type="search"
                                id="search-dropdown"
                                className="block text-base pl-10 p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                placeholder="Current content earnings"
                                min={1}
                                value={earnings}
                                onChange={handleEarningsChange}
                            />
                        </div>
                        <select
                            id="countries"
                            className="w-full md:w-4/12 mt-4 md:mt-0 text-base bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={selectedPlatform} // Set the value to the selected option from the state
                            onChange={handlePlatformChange} // Call the handleChange function when the selection changes
                        >
                            <option value="">Platform</option>
                            {platformOptions.map((option) => (
                                <option key={option.name} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {earnings && selectedPlatform !== "" && (
                    <div className="flex flex-col mt-5 items-center justify-center p-4 text-sm">
                        <span className='text-lg font-semibold pb-4'>
                            Your earnings with ONLYSXY
                        </span>
                        <span className="font-semibold text-5xl">
                            {newEarnings}
                            <span className="text-blueGray-500 text-3xl">
                                /month
                            </span>
                        </span>
                        <p className="text-base font-bold text-blueGray-400 mt-4">
                            <span className={"text-emerald-500"}>
                                <i
                                    className={"up fas fa-arrow-up"}
                                ></i>{" "}
                                {savings}% +${earningsDelta}
                            </span>
                        </p>
                        <p className="text-sm text-blueGray-400 mt-4">
                            {selectedPlatform} fees - {fees * 100}%
                        </p>
                        <p className="text-sm font-semibold text-blueGray-400 mt-1">
                            ONLYSXY fees - 5%
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comparer;
