import React, { useState, useEffect, useCallback } from 'react';
import Post from "./Post.js"
import AuthService from "services/auth.service";

function PostList(userPosts) {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

  useEffect(() => {
    // Filter out posts that are not ready to be displayed
    const filteredPosts = userPosts.userPosts.filter(post => {
      if (post.videoId) { // don't show posts with videoId (old)
        return false;
      }
      if (currentUser?.id === post.user || !post.video?.status) {
        return true; // show all posts
      } else {
        return post.video.status === 4; // show video posts with video status 4 (ready)
      }
    });
    setPosts(filteredPosts);
  }, [userPosts, currentUser]);

  // Load the latest 3 posts when the component mounts
  useEffect(() => {
    if (posts.length > 0) {
      // sort posts by date in descending order
      posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setVisiblePosts(posts.slice(0, 3));
      setLoaded(true);
    }
  }, [posts]);

  // Load more posts when the user scrolls to the bottom
  const handleScroll = useCallback(() => {
    if (document.documentElement.scrollHeight <=
      window.innerHeight + document.documentElement.scrollTop + 1
    ) {
      const visibleCount = visiblePosts.length;
      const remainingPosts = posts.slice(visibleCount);

      if (remainingPosts.length > 0) {
        const nextBatch = remainingPosts.slice(0, 3);
        setVisiblePosts(prevVisiblePosts => [...prevVisiblePosts, ...nextBatch]);
      }
    }
  }, [posts, visiblePosts]);

  useEffect(() => {
    if (loaded) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loaded, handleScroll]);

  return (
    <div className="w-full">
      {visiblePosts.map(post => (
        <Post key={post._id} post={post} />
      ))}
    </div>
  );
}

export default PostList;