import React, { useState, useEffect } from 'react';
import serverConf from 'config/server.config';
import userService from 'services/user.service';
import profilePicture from 'assets/img/team-1-800x800.jpg';
import Paywall from '../Paywall/Paywall';
import SubDialog from '../Dialogs/SubDialog';
import RegisterDialog from '../Dialogs/RegisterDialog';
import authService from 'services/auth.service';

const UserCard = ({ userInfo, isCreator, showRenew }) => {
    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
    const [user, setUser] = useState(null);
    const [expires, setExpires] = useState(0);
    const [avatarImage, setAvatarImage] = useState(null);
    const [showRegisterDialog, setShowRegisterDialog] = useState(false);
    const [showSubDialog, setShowSubDialog] = useState(false);
    const [showPaywall, setShowPaywall] = useState(false);
    const [fanBundle, setFanBundle] = useState(null);

    // Load the latest numVisibleUsers posts when the component mounts
    useEffect(() => {
        setUser(userInfo);
    }, [userInfo]);

    useEffect(() => {
        if (user) {
            const expiry = new Date(user.expiry);
            const expiresDays = Math.ceil((expiry.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
            setExpires(expiresDays);
            setAvatarImage(serverConf.HOST + serverConf.IMAGES_ROUTE + user.avatar);
        }
    }, [user]);

    const handleRenew = (event) => {
        event.stopPropagation();
        setShowSubDialog(true);
    }

    const closeSubDialog = () => {
        setShowSubDialog(false);
    }

    const openRegisterDialog = () => {
        setShowRegisterDialog(true);
    }

    const closeRegisterDialog = () => {
        setShowRegisterDialog(false);
    }

    const openPaywall = (selectedBundle) => {
        setFanBundle(selectedBundle);
    }

    const closePaywall = () => {
        setShowPaywall(false);
        setFanBundle(null);
    }

    useEffect(() => {
        if (fanBundle !== null) {
            setShowPaywall(true);
        }
    }, [fanBundle])

    const redirectUser = () => {
        window.location.href = '/' + user?.username;
    }

    return (
        <>
            {showRegisterDialog ? (
                <RegisterDialog user={user} action={'subscribe'} closeRegisterDialog={closeRegisterDialog} />
            ) : (null)}
            {showSubDialog ? (
                <SubDialog user={user} closeSubDialog={closeSubDialog} openRegisterDialog={openRegisterDialog} openPaywall={openPaywall} />
            ) : (null)}
            {showPaywall ? (
                <Paywall fromUsername={currentUser?.username} type={'fans'} bundle={fanBundle} user={user} closePaywall={closePaywall} />
            ) : (null)}
            <div onClick={redirectUser} className="relative flex flex-col bg-blueGray-50 min-w-0 break-words rounded-lg mb-6 shadow-lg cursor-pointer">
                <div className="flex-auto px-6 py-4">
                    <div className="flex flex-wrap items-center">
                        <div className="w-full pr-4 max-w-full flex-grow flex-1 flex items-center space-x-6">
                            <img className="w-16 h-16 rounded-full fit-cover" src={avatarImage} alt="" />
                            <div className="text-lg font-medium dark:text-white">
                                <div>{user?.username}</div>
                                <div className="text-base text-gray-500 dark:text-gray-400">@{user?.username}</div>
                            </div>
                        </div>
                        {showRenew &&
                            <div className="relative w-full md:w-auto hidden md:block pl-4 flex-initial">
                                <div className={
                                    "text-base leading-normal mt-0 font-bold " +
                                    ((expires < 10) ? (expires < 5 ? " text-red-500" : " text-amber-500") : " text-emerald-500")
                                }>
                                    Expires in {expires} {expires === 1 ? "day" : "days"}
                                </div>
                            </div>
                        }
                        {showRenew && !isCreator &&
                            <div className="relative md:w-auto pl-4 flex-initial">
                                <button
                                    className="bg-sky-500 active:bg-sky-600 uppercase text-white font-bold hover:shadow-md shadow text-base px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={handleRenew}
                                >
                                    {'Renew'}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserCard;