import { Navigate } from "react-router-dom";
import authService from "services/auth.service.js";

const AuthWrapper = ({ children }) => {
    const user = authService.getCurrentUser();

    return (
        (user !== null) ?
            (children) : (<Navigate to={'/login'} />)
    )
};

export default AuthWrapper;