import React, { useEffect, useState } from 'react';
import UserService from 'services/user.service';
import RegisterDialog from 'components/Own/Dialogs/RegisterDialog';

const FollowButton = ({ user, isUser }) => {

    const [isFollower, setIsFollower] = useState(false);
    const [showRegisterDialog, setShowRegisterDialog] = useState(false);

    useEffect(() => {
        if (isUser) {
            UserService.checkFollow(user.username)
                .then(response => {
                    setIsFollower(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [user]);

    const showRegister = () => {
        setShowRegisterDialog(true);
    }

    const closeRegisterDialog = () => {
        setShowRegisterDialog(false);
    }
    
    const handleFollow = () => {
        if (!isUser) {
            showRegister();
            return;
        }
        if (isFollower) {
            UserService.unfollowUser(user.username)
                .then(response => {
                    setIsFollower(false);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            UserService.followUser(user.username)
                .then(response => {
                    setIsFollower(true);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    return (
        <>
            {
                showRegisterDialog ? (
                    <RegisterDialog user={user} action={'follow'} closeRegisterDialog={closeRegisterDialog} />
                ) : (null)
            }
            < button
                className="bg-sky-500 active:bg-sky-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleFollow}
            >
                {isFollower ? 'Unfollow' : 'Follow'}
            </button >
        </>
    );
};

export default FollowButton;