import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import WalletInfo from './WalletInfo';
import WalletInfoContent from 'assets/wallets/WalletInfo';


const BitcoinHelper = () => {
    const [isCreator, setIsCreator] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [walletInfo, setWalletInfo] = useState([]);
    const [proWalletInfo, setProWalletInfo] = useState([]);

    const handleToggle = () => {
        setIsCreator(!isCreator);
    };

    const handleChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    useEffect(() => {
        const filteredWallets = WalletInfoContent.filter(item => item.countries.includes(selectedCountry));
        setWalletInfo(filteredWallets);
        const filteredProWallets = WalletInfoContent.filter(item => item.countries.includes("PRO"));
        setProWalletInfo(filteredProWallets);
    }, [selectedCountry]);

    return (
        <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-center lg:w-8/12">
                <p className="text-4xl text-center">
                    <span role="img" aria-label="star">
                        🌟
                    </span>
                </p>
                <h3 className="font-semibold text-3xl">
                    Bitcoin is easy
                </h3>
                <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                    If it's your first time using Bitcoin we want to give you a helping hand. <br />
                    Any Bitcoin wallet supporting Lightning will work with ONLYSXY.
                    Here are some recommendations to make it even easier. <br />
                </p>
                <div className="sm:block flex flex-col mt-10">
                    <div className="rounded-t mb-3 px-6">
                        <div className="text-center flex justify-center">
                            <label className="flex items-center justify-around w-full">
                                <span
                                    className={`w-full pr-12 text-right font-medium ${!isCreator
                                        ? "font-bold text-xl"
                                        : "text-base"
                                        } text-gray-900 dark:text-gray-300"`}
                                >
                                    Fan
                                </span>
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                    }}
                                    className="relative inline-flex items-center cursor-pointer"
                                >
                                    <input
                                        type="checkbox"
                                        value=""
                                        className="sr-only peer"
                                        checked={isCreator}
                                        onChange={handleToggle}
                                    />
                                    <div
                                        className={`w-11 h-6 bg-blue-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-pink-500`}
                                    ></div>
                                </div>
                                <span
                                    className={`w-full text-left pl-12 font-medium ${isCreator ? "font-bold text-xl" : "text-base"
                                        } text-gray-900 dark:text-gray-300`}
                                >
                                    Creator
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='mt-10 flex justify-center'>
                        <select
                            id="countries"
                            className="w-full lg:w-8/12 text-base bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={selectedCountry} // Set the value to the selected option from the state
                            onChange={handleChange} // Call the handleChange function when the selection changes
                        >
                            <option value="">Select a region</option>
                            <option value="WR">World</option>
                            <option value="US">United States </option>
                            <option value="EU">Europe</option>
                            <option value="UK">United Kingdom</option>
                            <option value="CA">Canada</option>
                            <option value="BR">Brazil</option>
                        </select>
                    </div>
                </div>
                {walletInfo.length > 0 && (
                    <div className={`flex ${walletInfo.length === 1 ? 'justify-center' : 'justify-between'} flex-wrap mt-10`}>
                        {walletInfo &&
                            walletInfo.map((wallet) => (
                                <WalletInfo
                                    key={uuidv4()}
                                    props={wallet}
                                    isCreator={isCreator}
                                />
                            ))
                        }
                    </div>
                )}
                {walletInfo.length > 0 && (
                    <div className={`flex ${proWalletInfo.length === 1 ? 'justify-center' : 'justify-between'} flex-wrap`}>
                        <div className="flex mt-10 items-center p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert">
                            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <span className="sr-only">Info</span>
                            <div className='text-left'>
                                <span className="font-medium">Pro Tip:</span> You can also buy or sell bitcoin directly to other individuals. Take a look at the following decentralized exchanges.
                            </div>
                        </div>
                        {proWalletInfo &&
                            proWalletInfo.map((wallet) => (
                                <WalletInfo
                                    key={uuidv4()}
                                    props={wallet}
                                    isCreator={isCreator}
                                />
                            ))
                        }
                    </div>
                )}
                <div className="text-center text-blueGray-500 font-italic text-sm mt-16">
                    ONLYSXY is not affiliated with any of the services mentioned above, and does not assume any responsibility for their use.
                </div>
            </div>
        </div>
    );
};

export default BitcoinHelper;
