import axios from 'axios';

class ConverterService {
    async getRate () {
        let UsdBtc;
        try {
            const response = await axios.get('https://api.coincap.io/v2/rates/bitcoin');
            UsdBtc = response.data.data.rateUsd;
        } catch (coinCapError) {
            console.error(coinCapError);
            try {
                const response = await axios.get('https://api.coinlore.net/api/ticker/?id=90'); //id=90 is bitcoin
                UsdBtc = response.data[0].price_usd;
            } catch (coinLoreError) {
                console.error(coinLoreError);
                throw new Error('Failed to fetch Bitcoin rate from both APIs');
            }
        }
        return 1 / (0.00000001 * UsdBtc);
    };
}

export default new ConverterService();