import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// layouts

import Auth from "layouts/Auth.js";
import DashboardLayout from "layouts/DashboardLayout";

// views without layouts

import RefWrapper from "components/Own/Wrapper/RefWrapper.js";
import Landing from "views/Landing.js";
import ProfilePage from "pages/ProfilePage.js";
import Index from "views/Index.js";
import Uploader from "components/Own/Uploader/Uploader";
import UserList from "components/Own/UserList/UserList";
import Settings from "views/admin/Settings";
import NavbarLayout from "layouts/NavbarLayout";
import NotFound from "components/Own/404/NotFound";
import AuthWrapper from "components/Own/Wrapper/AuthWrapper";
import TransactionPage from "components/Own/Transactions/TransactionPage";
import Terms from "views/Terms";

import authService from "services/auth.service";
import Feed from "views/Feed";
import Privacy from "views/Privacy";

const socketService = require('./services/socket.service');

function App() {
    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
    const [counter, setCounter] = useState(parseInt(localStorage.getItem('counter')) || 0);
    const [socket, setSocket] = useState(socketService.getSocket());
    const [payoutReceived, setPayoutReceived] = useState(false);
    const [paymentReceived, setPaymentReceived] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Listen for socket messages
        if (socket === null) {
            const newSocket = socketService.connectSocket();
            setSocket(newSocket);
        }
    }, []);

    useEffect(() => {
        if (socket !== null) {
            socket.on('message', handleMessage);
        }
    }, [socket]);

    const resetCounter = () => {
        setCounter(0);
        localStorage.setItem('counter', 0);
    };

    const handleMessage = (message) => {
        if (message.hasOwnProperty('event')) {
            switch (message.event) {
                case 'payout':
                    //reload dashboard
                    setPayoutReceived(true);
                    break;
                case 'paid':
                    //reload userPage
                    setPaymentReceived(true);
                    break;
                //TODO. integrate with chat
                case 'chat':
                    if (location.pathname !== '/chat') {
                        handleMessageCounter();
                    }
                    break;
                default:
                    console.log("Unknown event received");
            }
        } else {
            // Only increment the counter if the user is not on the /chat page
            if (location.pathname !== '/chat') {
                handleMessageCounter();
            }
        }
    };

    useEffect(() => {
        if (payoutReceived) {
            setPayoutReceived(false);
        }
    }, [payoutReceived]);

    useEffect(() => {
        if (paymentReceived) {
            setPaymentReceived(false);
        }
    }, [paymentReceived]);

    const handleMessageCounter = () => {
        const savedCounter = parseInt(localStorage.getItem('counter'))
        const newCounter = savedCounter + 1;
        setCounter(newCounter);
    };

    useEffect(() => {
        localStorage.setItem('counter', counter);
    }, [counter]);

    useEffect(() => {
        if (location.pathname === '/chat') {
            resetCounter();
        }
    }, [location.pathname]);

    return (
        <Routes>
            {/* add routes with layouts */}
            <Route path="/dashboard" element={<AuthWrapper children={<DashboardLayout payoutReceived={payoutReceived} children={<Settings />} />} />} />
            <Route path="/users" element={<AuthWrapper children={<NavbarLayout children={<UserList paymentReceived={paymentReceived} />} />} />} />
            <Route path="/history" element={<AuthWrapper children={<NavbarLayout children={<TransactionPage />} />} />} />
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/login/*" element={<Auth />} />
            <Route path="/register/*" element={<Auth />} />
            {/* add routes without layouts */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/discover" element={<Feed />} />
            <Route path="/feed" element={<AuthWrapper children={<Feed />} />} />
            <Route path="/upload" element={<AuthWrapper children={<Uploader />} />} />
            <Route path="/:uname" element={<ProfilePage paymentReceived={paymentReceived} />} />
            <Route path="/:uname/:ref" element={<RefWrapper />} />
            <Route path="/" element={<Index />} />
            <Route path="/404" element={<NotFound />} />
        </Routes>
    );
}

export default App;