import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import logo from "assets/svg/logo.svg"
import authService from "services/auth.service";
import tokenService from "services/token.service";

export default function Navbar() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
  const [isUser, setIsUser] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    if (currentUser !== null) {
      authService.checkUser()
        .then((response) => {
          if (response === false) {
            tokenService.removeUser();
            setCurrentUser(null);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          tokenService.removeUser();
          setCurrentUser(null);
          return;
        })
      setIsUser(true);
      if (currentUser.roles.includes("ROLE_CREATOR")) {
        setIsCreator(true);
      }
    }
  }, [currentUser]);

  return (
    <>
      {/* Navbar */}
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white-50 shadow">
        <div className="container px-4 mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="relative flex justify-between w-full">
            {/* Brand */}
            <div
              className="w-15 cursor-pointer"
              onClick={() => navigate('/' + ((isUser && window.location.pathname !== '/discover' )  ? (isCreator ? 'dashboard' : 'feed') : ''))}
            >
              <img
                alt="..."
                className="w-full min-h-12 min-w-140-px lg:min-h-16 align-middle border-none"
                src={logo}
              />
            </div>
            {currentUser ? (
              <ul className="flex-row list-none items-center flex lg:block">
                <UserDropdown user={currentUser} />
              </ul>
            ) : (
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          {currentUser ? (
            <> </>
          ) : (
            <>
              <div
                className={
                  "md:flex flex-grow self-end md:self-center md:items-center bg-transparent lg:bg-opacity-0 lg:shadow-none md:w-full" +
                  (navbarOpen ? " flex" : " hidden")
                }
                id="example-navbar-warning"
              >
                <ul className="flex flex-col md:flex-row list-none lg:ml-auto">
                  <li className="flex items-center justify-end">
                    <div
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold cursor-pointer"
                      onClick={() => navigate('/auth/login')}
                    >
                      Log In
                    </div>
                  </li>
                  <li className="flex items-center">
                    <button
                      className="bg-sky-500 text-white active:bg-sky-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => navigate('/auth/register')}
                    >
                      Join Now
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div >
      </nav >
      {/* End Navbar */}
    </>
  );
}
