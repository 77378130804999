import React, { useState, useEffect } from 'react';

import authService from 'services/auth.service';
import ImageUploader from './ImageUploader';
import VideoUploader from './VideoUploader';

import AdminNavbar from 'components/Navbars/AdminNavbar.js';


function Uploader() {
    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [isImage, setIsImage] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [file, setFile] = useState(null);

    useEffect(() => {
        const currentUser = authService.getCurrentUser();
        if (!currentUser) {
            window.location.href = '/auth/login';
            return;
        } else if (!currentUser.roles.includes('ROLE_CREATOR')) {
            window.location.href = '/dashboard';
            return;
        }
    }, []);

    const handleFile = (newFile) => {
        if (newFile) {
            if (newFile.type.match(/image\/*/)) {
                setIsImage(true);
                setFile(newFile);
                return;
            } else if (newFile.type.match(/video\/*/)) {
                setIsVideo(true);
                setFile(newFile);
                return;
            } else {
                setError("File is not an image or video.");
                return;
            }
        }
    };

    const handleChange = (event) => {
        handleFile(event.target.files[0]);
        event.target.value = '';
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFile(event.dataTransfer.files[0]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <AdminNavbar />
            <div className="flex w-full min-h-screen">
                {!file &&
                    <div className="flex flex-grow"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}>
                        <section
                            className="relative py-16 bg-blueGray-100 flex-grow flex items-center"
                        >
                            <div className="container mx-auto px-4 h-full w-full items-center flex justify-center">
                                <div className="flex items-center justify-center w-full h-7/10">
                                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg
                                                aria-hidden="true"
                                                className="w-16 h-16 mb-3 text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                ></path>
                                            </svg>
                                            <p className="mb-2 text-base text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">Click to upload</span> or drag and drop
                                            </p>
                                        </div>
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            accept="image/*, video/*"
                                            onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                        </section>
                    </div>
                }
                {isImage &&
                    <ImageUploader file={file} />
                }
                {isVideo &&
                    <VideoUploader file={file} />
                }
            </div>
        </>
    );
}
export default Uploader;