import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import serverConf from "config/server.config";

import defAvatar from "assets/img/team-2-800x800.jpg";
import authService from "services/auth.service";

const UserDropdown = ({ user }) => {
  const navigate = useNavigate();

  const [isCreator, setIsCreator] = useState(false);
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useEffect(() => {
    if (user !== null) {
      resolverUrls(user.avatar);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (user.roles.includes("ROLE_CREATOR")) {
        setIsCreator(true);
      }
    }
  }, [user]);

  const resolverUrls = (avatarName) => {
    var avatar = avatarName ? serverConf.HOST + serverConf.IMAGES_ROUTE + avatarName : defAvatar;
    user.avatarUrl = avatar;
  }

  const logout = () => {
    authService.logout();
  }

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 lg:w-16 lg:h-16 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="aspect-1 shadow-lg align-middle border-none"
              style={{ borderRadius: '50%', objectFit: 'cover' }}
              src={user?.avatarUrl}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        {isCreator ?
          (
            <div
              onClick={() => navigate('/upload')}
              className={
                "cursor-pointer text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-sky-700"
              }
            >
              <div className="self-center flex justify-start items-center text-sm leading-normal text-sky-400 font-bold">
                <i className="fas fa-plus flex justify-center w-5 mr-2 text-lg text-sky-400"></i>{" "}
                Post
              </div>
            </div>
          ) : (
            <>
              <div
                onClick={() => navigate('/feed')}
                className={
                  "cursor-pointer text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-sky-700"
                }
              >
                <div className="self-center flex justify-start items-center text-sm leading-normal text-sky-400 font-bold">
                  <i className="fas fa-star flex justify-center w-5 mr-2 text-lg text-sky-400"></i>{" "}
                  Feed
                </div>
              </div>
            </>
          )}
        <div
          onClick={() => navigate('/dashboard')}
          className={
            "cursor-pointer text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          <div className="self-center flex justify-start items-center text-sm leading-normal text-blueGray-400 font-bold">
            <i className="fas fa-gear flex justify-center w-5 mr-2 text-lg text-blueGray-400"></i>{" "}
            Dashboard
          </div>
        </div>
        <div
          onClick={() => window.location.href = '/' + user.username}
          className={
            "cursor-pointer text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          <div className="self-center flex justify-start items-center text-sm leading-normal text-blueGray-400 font-bold">
            <i className="fas fa-user-circle flex justify-center w-5 mr-2 text-lg text-blueGray-400"></i>{" "}
            Profile
          </div>
        </div>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <button
          className="py-1 px-4 block w-full whitespace-nowrap text-red-500 self-center flex justify-start items-center text-sm leading-normal background-transparent font-bold text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={logout}
        >
          <i className="fas fa-power-off flex justify-center w-5 mr-2 text-lg text-red-500"></i>{" "}
          Logout
        </button>
      </div>
    </>
  );
};

export default UserDropdown;