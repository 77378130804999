import React from "react";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

export default function DashboardLayout({ children }) {
    return (
        <>
                <AdminNavbar />
                {/* Header */}
                <div className="mt-16">
                    {children}
                    <FooterAdmin />
                </div>
        </>
    );
}
