
import React, { useState, useEffect } from 'react';
import userService from 'services/user.service';
import serverConf from 'config/server.config';

function Transaction({ index, transaction }) {

    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [iconClass, setIconClass] = useState('');
    const [colorClass, setColorClass] = useState('');
    const [typeIconClass, setTypeIconClass] = useState('');
    const [typeColorClass, setTypeColorClass] = useState('');
    const [status, setStatus] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [amount, setAmount] = useState('');
    const [amountUSD, setAmountUSD] = useState('');
    const [avatar, setAvatar] = useState('');


    useEffect(() => {
        // Determine the icon and color based on postType
        switch (status) {
            case 'Wait':
                setIconClass('fas fa-hourglass-half');
                setColorClass('bg-yellow-200 text-yellow-600');
                break;
            case 'Paid':
                setIconClass('fas fa-check');
                setColorClass('bg-emerald-200 text-emerald-600');
                break;
            case 'Error':
                setIconClass('fas fa-exclamation');
                setColorClass('bg-red-200 text-red-600');
                break;
            case 'Pending':
                setIconClass('fas fa-hourglass-half');
                setColorClass('bg-blueGray-200 text-blueGray-500');
                break;
            default:
                break;
        }
    }, [status]);

    useEffect(() => {
        let timestamp;
        if (transaction.status === 'Paid') {
            timestamp = new Date(transaction.paidAt);
            prepareTime(timestamp);
            setStatus('Paid');
        } else if (transaction.status === 'Error') {
            timestamp = new Date(transaction.createdAt);
            prepareTime(timestamp);
            const now = Date.now();
            const diff = now - timestamp;
            if (diff > 5 * 60 * 1000) {
                setStatus('Error');
            } else {
                setStatus('Wait');
            }
        } else if (transaction.status === 'Pending') {
            timestamp = new Date(transaction.createdAt);
            prepareTime(timestamp);
            setStatus('Pending');
        }
    }, [transaction.paidAt, transaction.createdAt, transaction.status]);

    const prepareTime = (timestamp) => {
        setTime(timestamp.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
        }));
        setDate(timestamp.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }));
    }

    useEffect(() => {
        switch (transaction.type) {
            case 'invoice':
                setTitle('@' + transaction.username);
                setTypeIconClass('fas fa-arrow-down');
                setTypeColorClass('bg-emerald-200 text-emerald-600');
                if (transaction.months) {
                    setSubtitle(`${transaction.months} ${transaction.months === 1 ? "Month" : "Months"} Subscription`);
                } else if (transaction.ppvId) {
                    setSubtitle('Pay-Per-View');
                }
                break;
            case 'referral':
                setTitle('@' + transaction.username);
                setSubtitle('Referral');
                setTypeIconClass('fas fa-arrow-down');
                setTypeColorClass('bg-emerald-200 text-emerald-600');
                break;
            case 'withdrawal':
                setTitle('Withdrawal');
                let toAddress = transaction.toAddress;
                if (window.innerWidth <= 500) { // Adjust the breakpoint as needed
                    if (toAddress.length > 20) {
                        toAddress = toAddress.substring(0, 10) + '...' + toAddress.substring(toAddress.length - 10);
                    }
                } else {
                    if (toAddress.length > 50) {
                        toAddress = toAddress.substring(0, 15) + '...' + toAddress.substring(toAddress.length - 15);
                    }
                }
                setSubtitle(toAddress);
                setTypeIconClass('fas fa-arrow-up');
                setTypeColorClass('bg-sky-200 text-sky-600');
                break;
            default:
                break;
        }
    }, [transaction.type]);

    useEffect(() => {
        if (transaction.amount !== undefined) {
            const amountNumber = parseFloat(transaction.amount);
            const amountText = amountNumber.toLocaleString(undefined, { maximumFractionDigits: 0 });
            setAmount(amountText + ' sats');
            if (transaction.amountUSD !== undefined) {
                const amountUSDNumber = parseFloat(transaction.amountUSD);
                const amountUSDText = amountUSDNumber.toLocaleString(undefined, { maximumFractionDigits: 2 });
                setAmountUSD('~ $' + amountUSDText);
            }
        }
    }, [transaction.amount, transaction.amountUSD]);

    useEffect(() => {
        if (transaction.isUser === true) {
            userService.getUser(transaction.username)
                .then(response => {
                    if (response.data.user.avatar !== undefined) {
                        setAvatar(serverConf.HOST + serverConf.IMAGES_ROUTE + response.data.user.avatar);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [transaction.isUser]);


    return (
        <tr
            key={index}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
            <td
                scope="row"
                className="px-4 py-4 flex flex-col lg:flex-row items-center justify-start"
            >
                <div className="flex items-center text-gray-900 whitespace-nowrap dark:text-white w-full lg:w-9/12">
                    {avatar ? (
                        <img
                            className="w-10 h-10 rounded-full fit-cover"
                            src={avatar}
                            alt="Profile"
                        />
                    ) : (
                        <div className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full ${typeColorClass}`}>
                            <i className={`${typeIconClass} text-lg`}></i>
                        </div>
                    )}
                    <div className="pl-3">
                        <div className="text-base font-semibold">
                            {title}
                        </div>
                        <div className="font-normal text-gray-500">
                            {subtitle}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-baseline justify-start text-gray-900 whitespace-nowrap dark:text-white w-full mt-3 lg:mt-0 lg:w-3/12 lg:pl-8">
                    <div className="text-base font-semibold">
                        {amount}
                    </div>
                    <div className="font-semibold text-sm ml-2 text-blueGray-400">
                        {amountUSD}
                    </div>
                </div>
            </td>
            <td className="pr-4 py-4">
                <div className="flex flex-col items-end justify-between h-full text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="flex flex-col lg:flex-row items-end lg:items-center text-xs justify-end w-full">
                        <div className='w-full lg:w-6/12 flex justify-end items-center text-gray-500'>
                            <i className="far fa-clock mr-1 text-xs"></i>{" "}
                            <div className='flex flex-col lg:flex-row justify-end'>
                                <div className='text-xs font-normal'>
                                    {date}
                                </div>
                                <div className='lg:ml-1 text-xs font-normal text-center'>
                                    {time}
                                </div>
                            </div>
                        </div>
                        <div className="ml-2 mt-6 lg:mt-0 w-2/10 flex justify-end">
                            <span className={`text-2xs font-semibold inline-block py-1 px-2 rounded ${colorClass}`}>
                                <i className={`${iconClass} text-2xs mr-1`}></i> {status}
                            </span>
                        </div>
                    </div>
                    <div className="hidden lg:block font-normal text-2xs text-gray-500 mt-3">
                        {transaction.id}
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default Transaction;