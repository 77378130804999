import React, { useState } from 'react';

const PreviewText = ({ text }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="flex flex-wrap justify-center">
            <div className="w-full lg:w-9/12 px-4">
                <p className={`text-lg mb-3 leading-relaxed text-blueGray-700 ${expanded ? 'clamp-none' : 'clamp-2'}`} >
                    {text}
                </p>
                <button
                    className="text-sky-500 background-transparent font-normal px-3 py-1 text-base outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={handleExpandClick}
                    type="button"
                >
                    {expanded ? 'Show less' : 'Show more'}
                </button>
            </div>
        </div>
    );
};

export default PreviewText;