import React, { useEffect } from 'react';
import profileImage from "assets/img/team-2-800x800.jpg";
import serverConf from 'config/server.config';

const DialogHeader = ({ user }) => {
    
    user.avatarUrl = user.avatar ? serverConf.HOST + serverConf.IMAGES_ROUTE + user.avatar : profileImage;

    return (
        <div className="px-6 mb-16">
            <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                        <div className="flex justify-center mt-6 -mb-16 md:-my-16 ">
                            <img
                                alt="..."
                                src={user.avatarUrl || profileImage}
                                className="aspect-1 shadow-xl h-auto align-middle border-none"
                                style={{
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    maxWidth: '150px',
                                    maxHeight: '150px'
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DialogHeader;