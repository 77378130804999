import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import * as tus from 'tus-js-client';
import streamService from 'services/stream.service';
import authService from 'services/auth.service';
import userService from 'services/user.service';

import CreatePost from '../Post/CreatePost';

const VideoUploader = ({ file }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [post, setPost] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const navigate = useNavigate();

    const currentUser = authService.getCurrentUser();

    const handleFile = (file) => {
        if (file) {
            let newPost = {
                video: URL.createObjectURL(file),
                user: currentUser
            };
            setPost(newPost);
        }
    };

    useEffect(() => {
        handleFile(file);
    }, [file]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    const handleUpload = async (caption, postType, price) => {

        const response = await streamService.createVideo("test_video1");

        const endpoint = "https://video.bunnycdn.com/tusupload";
        const retryDelays = [0, 3000, 5000, 10000, 20000, 60000, 60000];

        let headers = response.data.upload_headers;
        let metadata = response.data.upload_metadata;

        metadata.filetype = file.type;

        const upload = new tus.Upload(file, {
            endpoint,
            retryDelays,
            headers,
            metadata,
            onError: (error) => {
                console.error('Upload failed:', error);
                if (onError) onError(error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const progress = (bytesUploaded / bytesTotal) * 100;
                setUploadProgress(progress);
                if (onProgress) onProgress(bytesUploaded, bytesTotal);
            },
            onSuccess: () => {
                if (onSuccess) onSuccess(headers.VideoId, headers.LibraryId, caption, postType, price);
            },
        });

        upload.findPreviousUploads().then((previousUploads) => {
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            upload.start();
        });
    };

    const createPost = (videoId, libraryId, caption, postType, price) => {
        // Create body for request
        const body = {
            videoId,
            libraryId,
            caption,
            postType,
            price
        };

        // Make a POST request to the server with the image data
        userService.createVideoPost(body)
            .then((response) => {
                if (response.status === 200) {
                    setRedirect("/" + currentUser.username);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const onSuccess = (videoId, libraryId, caption, postType, price) => {
        createPost(videoId, libraryId, caption, postType, price);
    }

    const onError = (error) => {
        console.log(error);
    }

    const onProgress = () => {
        //onProgress
    }

    return (
        <>
            {post &&
                <CreatePost post={post} handleUpload={handleUpload} uploadProgress={uploadProgress} />
            }
        </>
    );
};

export default VideoUploader;