import React, { useState, useEffect } from "react";

// components

import CardSettings from "components/Cards/CardSettings.js";
import CardProfile from "components/Cards/CardProfile.js";
import profileImage from "assets/img/team-1-800x800.jpg";

import serverConf from "config/server.config";
import authService from "services/auth.service";
import userService from "services/user.service";

export default function Settings() {

  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
  const [user, setUser] = useState(false);

  const update = () => {
    userService.getOwnUser()
      .then(response => {
        const userData = response.data.user;
        userData.avatarUrl = userData.avatar ? serverConf.HOST + serverConf.IMAGES_ROUTE + userData.avatar : profileImage;
        userData.bannerUrl = userData.banner ? serverConf.HOST + serverConf.IMAGES_ROUTE + 'thumbnail_' + userData.banner : profileImage;
        setUser(userData);
      })
      .catch(error => {
        console.error(error);
        // navigate("/404");
      })
  }

  useEffect(() => {
    if (currentUser !== null) {
      userService.getOwnUser()
        .then(response => {
          const userData = response.data.user;
          userData.avatarUrl = userData.avatar ? serverConf.HOST + serverConf.IMAGES_ROUTE + userData.avatar : profileImage;
          userData.bannerUrl = userData.banner ? serverConf.HOST + serverConf.IMAGES_ROUTE + 'thumbnail_' + userData.banner : profileImage;
          setUser(userData);
        })
        .catch(error => {
          console.error(error);
          // navigate("/404");
        })
    } else {
      // navigate("/login");
      window.location.href = "/auth/login";
    }
  }, [currentUser]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 md:px-4">
          {user &&
            <CardSettings user={user} update={update} />
          }
        </div>
        <div className="w-full lg:w-4/12 md:px-4">
          {user &&
            <CardProfile user={user} />
          }
        </div>
      </div>
    </>
  );
}
