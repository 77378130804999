import React from 'react';

const PlayButton = () => {
    return (
        <button
            style={{
                transform:
                    "translate(-50%, -50%)",
            }}
            className="h-100-px w-100-px absolute top-50 left-50 bg-black-70 text-white rounded-full font-bold py-2 px-4 font-bold cursor-default border-none flex items-center justify-center">
            <i className="fas fa-play text-4xl"></i>{" "}
        </button>
    );
};

export default PlayButton;