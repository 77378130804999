import React, { useEffect, useState } from 'react';
import authService from 'services/auth.service';
import userService from 'services/user.service';
import RegisterDialog from 'components/Own/Dialogs/RegisterDialog';
import Paywall from './Paywall/Paywall';

const SubBlock = ({ user, openRegisterDialog, openPaywall }) => {

    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser())
    const [isUser, setIsUser] = useState(false);
    const [isFan, setIsFan] = useState(false);
    const [expires, setExpires] = useState(null);
    const [prices, setPrices] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [showPaywall, setShowPaywall] = useState(false);
    const [showRegisterDialog, setShowRegisterDialog] = useState(false);
    const [fanBundle, setFanBundle] = useState(null);

    function handleClick(selectedBundle) {
        if (isUser) {
            if (openPaywall !== undefined) {
                openPaywall(selectedBundle);
            } else {
                setShowPaywall(true);
                setFanBundle(selectedBundle);
            }
        } else {
            setShowRegisterDialog(true);
            if (openRegisterDialog !== undefined) {
                openRegisterDialog();
            }
        }
    };

    const closeRegisterDialog = () => {
        setShowRegisterDialog(false);
    }

    const closePaywall = () => {
        setShowPaywall(false);
        setFanBundle(null);
    }

    useEffect(() => {
        if (currentUser === null) {
            setIsUser(false);
        } else {
            setIsUser(true);
            userService.checkFans(user.username)
                .then(response => {
                    setIsFan(response.data.isFan);
                    const expiry = new Date(response.data.expiry);
                    const expiresDays = Math.ceil((expiry.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
                    setExpires(expiresDays);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [currentUser]);

    const calcPrices = (price) => {
        const discount3m = 10;
        const discount6m = 20;
        const discount12m = 35;
        setDiscounts([0, discount3m, discount6m, discount12m])
        const price1m = price.toFixed(2);
        const price3m = (3 * price * (1 - (discount3m * 0.01))).toFixed(2);
        const price6m = (6 * price * (1 - (discount6m * 0.01))).toFixed(2);
        const price12m = (12 * price * (1 - (discount12m * 0.01))).toFixed(2);
        setPrices([price1m, price3m, price6m, price12m]);
    }

    useEffect(() => {
        if (user !== null) {
            calcPrices(user.price)
        }
    }, [user]);

    return (
        <>
            {showPaywall ? (
                <Paywall fromUsername={currentUser?.username} type="fans" bundle={fanBundle} user={user} closePaywall={closePaywall} />
            ) : (null)}
            {showRegisterDialog ? (
                <RegisterDialog user={user} action={'subscribe'} closeRegisterDialog={closeRegisterDialog} />
            ) : (null)}
            <div className="w-90 mx-auto flex flex-wrap justify-center">
                <div className="w-full flex flex-wrap justify-center">
                    {isFan &&
                        <div className="w-full lg:w-6/12 px-4 lg:order-2 lg:text-right lg:self-center">
                            <div className={
                                "text-base leading-normal mt-0 mb-2 font-bold " +
                                ((expires < 10) ? (expires < 5 ? " text-red-500" : " text-amber-500") : " text-emerald-500")
                            }>
                                Expires in {expires} {expires === 1 ? "day" : "days"}
                            </div>
                        </div>
                    }
                    <div className={
                        "px-4 lg:order-1 lg:text-left lg:self-center"
                        + (isFan ? " w-full lg:w-6/12" : " w-full")
                    }>
                        <div className="text-xl leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                            {isFan ? "Renew" : "Subscribe"}
                        </div>
                    </div>
                </div>
                <button
                    className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 uppercase text-white font-bold hover:shadow-lg shadow text-base px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150 flex justify-between"
                    type="button"
                    onClick={() => handleClick(1)}
                >
                    <span className="lg:w-6/12 md:pr-12 text-right">
                        1 Month
                    </span>
                    <span className="lg:w-6/12 md:pl-12 text-left">
                        ${prices[0]}
                    </span>
                </button>
                <button
                    className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 uppercase text-white font-bold hover:shadow-lg shadow text-base px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150 flex justify-between"
                    type="button"
                    onClick={() => handleClick(3)}
                >
                    <span className="lg:w-6/12 md:pr-12 text-right">
                        3 Months
                    </span>
                    <span className="lg:w-6/12 md:pl-12 text-left">
                        ${prices[1]} - {discounts[1]}% OFF!
                    </span>
                </button>
                <button
                    className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 uppercase text-white font-bold hover:shadow-lg shadow text-base px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150 flex justify-between"
                    type="button"
                    onClick={() => handleClick(6)}
                >
                    <span className="lg:w-6/12 md:pr-12 text-right">
                        6 Months
                    </span>
                    <span className="lg:w-6/12 md:pl-12 text-left">
                        ${prices[2]} - {discounts[2]}% OFF!
                    </span>
                </button>
                <button
                    className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 uppercase text-white font-bold hover:shadow-lg shadow text-base px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150 flex justify-between"
                    type="button"
                    onClick={() => handleClick(12)}
                >
                    <span className="lg:w-6/12 md:pr-12 text-right">
                        12 Months
                    </span>
                    <span className="lg:w-6/12 md:pl-12 text-left">
                        ${prices[3]} - {discounts[3]}% OFF!
                    </span>
                </button>
            </div>
        </>
    )
};

export default SubBlock;