import React from 'react';
import { useNavigate } from 'react-router-dom';
import DialogHeader from './DialogHeader';

const RegisterDialog = ({ user, action, closeRegisterDialog }) => {
    const navigate = useNavigate();
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={closeRegisterDialog}
            >
                <div className="relative w-auto my-6 mx-10_100 max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <DialogHeader user={user} />
                        {/*body*/}
                        <div className="relative p-6 px-6 flex-auto">
                            <button
                                className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 text-white font-bold hover:shadow-lg shadow text-base px-24 py-2 my-4 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => { navigate(`/auth/register?redirect=${user.username}`); }}
                            >
                                Join now to {action}
                            </button>
                            <a
                                href={`/auth/login?redirect=${user.username}`}
                                className="text-sky-500 background-transparent font-normal px-3 py-1 text-base outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 justify-center flex"
                            >
                                Already have an account?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default RegisterDialog;