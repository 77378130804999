import api from './api';

const URL_PREFIX = '/stream/';

class StreamService {
    createVideo(title) {
        return api.post(URL_PREFIX + "createVideo", {
            title,
        });
    }
}

export default new StreamService();