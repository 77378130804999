import api from './api';

const URL_PREFIX = '/test/';

class UserService {
  getPublicContent() {
    return api.get(URL_PREFIX + 'all');
  }

  getAllPosts() {
    console.log('getAllPosts');
    return api.get(URL_PREFIX + 'getAllPosts');
  }

  getUserPosts(username) {
    return api.get(URL_PREFIX + 'getPosts/' + username);
  }

  getFollowedPosts() {
    return api.get(URL_PREFIX + 'getFollowedPosts');
  }

  getUser(username) {
    return api.get(URL_PREFIX + 'getUser/' + username);
  }

  getOwnUser() {
    return api.get(URL_PREFIX + 'getOwnUser');
  }

  getUserById(userId) {
    return api.get(URL_PREFIX + 'getUserById/' + userId);
  }

  getConversations() {
    return api.get(URL_PREFIX + 'getConversations/');
  }

  genNewConversation(username) {
    return api.get(URL_PREFIX + 'genNewConversation/' + username);
  }

  getMessages(conversationId) {
    return api.get(URL_PREFIX + 'getMessages/' + conversationId);
  }

  getUsers(selectedComponent) {
    return api.get(URL_PREFIX + 'getUsers/' + selectedComponent);
  }

  async updateUser(username, name, email, lnAddress, desc, price, pricePPV, password, newPassword) {
    return api.put(URL_PREFIX + 'updateUser', {
      username,
      name,
      email,
      lnAddress,
      desc,
      price,
      pricePPV,
      password,
      newPassword
    });
  }

  followUser(username) {
    return api.put(URL_PREFIX + 'followUser', {
      username
    });
  }

  unfollowUser(username) {
    return api.put(URL_PREFIX + 'unfollowUser', {
      username
    });
  }

  checkFollow(username) {
    return api.get(URL_PREFIX + 'checkFollow/' + username);
  }

  checkFans(username) {
    return api.get(URL_PREFIX + 'checkFans/' + username);
  }

  deleteFan(username) {
    return api.put(URL_PREFIX + 'deleteFan', {
      username
    });
  }

  deleteConversations() {
    return api.delete(URL_PREFIX + 'deleteConversations');
  }

  upload(formData) {
    return api.post(URL_PREFIX + 'upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        isMultipart: true
      }
    );
  }

  createVideoPost(body) {
    return api.post(URL_PREFIX + 'createVideoPost', body);
  }

  uploadChat(formData) {
    return api.post(URL_PREFIX + 'uploadChat',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        isMultipart: true
      }
    );
  }

  uploadAvatar(formData) {
    return api.post(URL_PREFIX + 'uploadAvatar',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        isMultipart: true
      }
    );
  }

  uploadBanner(formData) {
    return api.post(URL_PREFIX + 'uploadBanner',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        isMultipart: true
      }
    );
  }

  uploadAdmin(formData) {
    return api.post(URL_PREFIX + 'uploadWithName',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        isMultipart: true
      }
    );
  }

  getImage(filename) {
    return api.get(URL_PREFIX + 'getImage/' + filename);
  }


  getTransactions() {
    return api.get(URL_PREFIX + 'getTransactions');
  }

  loadDashboard() {
    return api.get(URL_PREFIX + 'loadDashboard');
  }

  getUserBoard() {
    return api.get(URL_PREFIX + 'user');
  }

  getModeratorBoard() {
    return api.get(URL_PREFIX + 'mod');
  }

  getAdminBoard() {
    return api.get(URL_PREFIX + 'admin');
  }

  deleteUser() {
    return api.delete(URL_PREFIX + 'deleteUser');
  }

  deletePost(postId) {
    return api.delete(URL_PREFIX + 'deletePost/' + postId);
  }

}

const userServiceInstance = new UserService();

export default userServiceInstance;