import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Transaction from './Transaction';
import userService from 'services/user.service.js';
import NoData from 'components/Own/ui/NoData';

function TransactionPage() {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        userService.getTransactions()
            .then(response => {
                setTransactions(response.data.transactions);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const goBack = () => {
        navigate("/dashboard");
    }

    return (
        <div className="relative flex flex-col items-center pt-16 w-full min-h-screen">
            <div className="relative flex flex-col items-center pt-4 w-full lg:w-9/12">
                <div className="absolute top-0 left-0 mt-4 mr-4">
                    <button className="text-sky-500 background-transparent font-bold uppercase px-6 py-2 text-base outline-none focus:outline-none ease-linear transition-all duration-150"
                        onClick={goBack}
                        type="button"
                    >
                        <i className="fas fa-chevron-left"></i> Back
                    </button>
                </div>
                <div className="text-2xl font-medium dark:text-white text-base leading-normal mb-8 font-bold">
                    <h2>Transactions</h2> {/* add the title */}
                </div>
                <div className="px-4 w-full lg:w-10/12">
                    {transactions?.length === 0 ? (
                        <>
                            <NoData text={'Your transaction history will appear here'}></NoData>
                        </>
                    ) : (
                        <>
                            <div className="relative w-full max-w-full shadow-md sm:rounded-lg">
                                <table className="w-full max-w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <tbody className="max-w-full">
                                        {transactions?.map((transaction, index) => (
                                            <Transaction index={index} transaction={transaction} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )
                    }
                </div>
            </div>
        </div>
    );
}

export default TransactionPage;