import React, { useState, useEffect } from 'react';
import userService from '../services/user.service';
import authService from '../services/auth.service';
import PostList from 'components/Own/Post/PostList';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import NoData from 'components/Own/ui/NoData';

function Feed() {
  const [posts, setPosts] = useState([]);
  const [hasPosts, setHasPosts] = useState(false);
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    if (window.location.pathname === '/discover') {
      loadGlobalFeed();
    } else {
      if (currentUser === null) {
        loadGlobalFeed();
      } else {
        userService.getFollowedPosts()
          .then(response => {
            if (response.data.posts.length > 0) {
              setHasPosts(true);
              setPosts(response.data.posts);
            }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading state to false after the request completes
          });
      }
    }
  }, [currentUser]);

  const loadGlobalFeed = () => {
    userService.getAllPosts()
      .then(response => {
        if (response.data.posts.length > 0) {
          setHasPosts(true);
          setPosts(response.data.posts);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false after the request completes
      });
  }

  return (
    <div className='bg-blueGray-200'>
      <AdminNavbar />
      {isLoading ? ( // Show loading indicator while loading
        <p>Loading...</p>
      ) : hasPosts ? (
        <div className='pt-48'>
          <PostList userPosts={posts} />
        </div>
      ) : (
        <NoData text={'Follow your first creator and start filling your feed'}></NoData>
      )}
    </div>
  );
}

export default Feed;