import React, { useState, useRef } from "react";
import Cropper from 'react-easy-crop';
import userService from "services/user.service";
import getCroppedImg from "services/getCroppedImg";
import tokenService from "services/token.service";
import bannerImages from "assets/img/Cover.webp";
import md5 from "md5";
import serverConf from "config/server.config";

// components

export default function CardProfile({ user }) {
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [banner, setBanner] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);

  const [openEditor, setOpenEditor] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const imageInputRef = useRef(null);

  const handleButtonClick = () => {
    imageInputRef.current.click();
  };

  let hash = md5(user.username.toLowerCase() + serverConf.REF_TOKEN);
  const ref = hash.slice(-3);
  console.log(ref);

  const getCroppedImage = async () => {
    setIsLoading(true);
    if (avatarBlob) {
      var croppedImageData = await getCroppedImg(
        avatar,
        croppedAreaPixels,
        300, 300,
      );
    } else {
      var croppedImageData = await getCroppedImg(
        banner,
        croppedAreaPixels,
        2000, 500,
      );
    }
    await handleUpload(croppedImageData);
    closeEditor();
  };

  const handleUpload = async (croppedImage) => {
    croppedImage.name = "blob.jpg"
    const formData = new FormData();
    formData.append("image", croppedImage, croppedImage.name);
    if (avatarBlob) {
      userService.uploadAvatar(formData)
        .then((response) => {
          if (response.status === 200) {
            tokenService.updateUser(response.data.user);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userService.uploadBanner(formData)
        .then((response) => {
          if (response.status === 200) {
            tokenService.updateUser(response.data.user);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangeAvatar = (event) => {
    setAvatar(event.target.files[0]);
    setAvatarBlob(URL.createObjectURL(event.target.files[0]));
    setOpenEditor(true);
    setBanner(null);
    setBannerBlob(null);
    event.target.value = '';
  };

  const handleChangeBanner = (event) => {
    setBanner(event.target.files[0]);
    setBannerBlob(URL.createObjectURL(event.target.files[0]));
    setOpenEditor(true);
    setAvatar(null);
    setAvatarBlob(null);
    event.target.value = '';
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const onZoomChange = (e) => {
    setZoom(e);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <>
      <div className="relative -mt-8 pt-8">
        {!openEditor &&
          <label htmlFor="banner-image">
            <img
              alt="..."
              src={user?.bannerUrl}
              className="h-auto align-middle border-none cursor-pointer aspect-4"
            />
            <input
              id="banner-image"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleChangeBanner} />
          </label>
        }
        <div
          className={`relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl mt-0 ${openEditor ? 'min-h-600 rounded-lg' : 'rounded-b-lg'}`}>
          <div className="px-6">
            {openEditor ? (
              <>
                <div className="absolute top-40px bottom-80px left-1 right-1">
                  <Cropper
                    image={avatarBlob || bannerBlob}
                    crop={crop}
                    zoom={zoom}
                    aspect={avatarBlob ? 1 : 4}
                    cropShape={avatarBlob ? "round" : "rect"}
                    showGrid={true}
                    zoomWithScroll={true}
                    onZoomChange={onZoomChange}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                  />
                </div>
                <div className="absolute top-0 left-0 h-40px items-center flex">
                  <button className="text-sky-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={closeEditor}
                    type="button"
                  >
                    <i className="fas fa-chevron-left"></i> Back
                  </button>
                </div>
                <div className="absolute bottom-0 left-0 right-0 w-full p-4">
                  <button
                    className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-600 text-white font-bold hover:shadow-lg shadow text-base py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={getCroppedImage}
                  >
                    Save Changes
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-4 flex justify-center">
                    <div className="relative flex justify-center -my-16 ">
                      <label htmlFor="profile-image">
                        <button
                          type="button"
                          onClick={handleButtonClick}
                          className="bottom-0 right-1/2 translate-y-1/2 translate-x-1/2 absolute text-white bg-sky-500 hover:bg-sky-600 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:bg-sky-500 dark:hover:bg-sky-600"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style={{ fill: 'white' }}>
                            <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                          </svg>
                        </button>
                        <img
                          alt="..."
                          src={user?.avatarUrl}
                          className="aspect-1 shadow-xl h-auto align-middle border-none cursor-pointer"
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            maxWidth: '150px',
                            maxHeight: '150px'
                          }}
                        />
                        <input
                          id="profile-image"
                          ref={imageInputRef}
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={handleChangeAvatar} />
                      </label>
                    </div>
                  </div>
                  <div className="w-full px-4 text-center mt-20">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mx-2 w-70-px p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {user?.numPosts}
                        </span>
                        <span className="text-sm text-blueGray-400">Posts</span>
                      </div>
                      <div className="mx-2 w-70-px p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {user?.numPhotos}
                        </span>
                        <span className="text-sm text-blueGray-400">Photos</span>
                      </div>
                      <div className="mx-2 w-70-px p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          {user?.numVideos}
                        </span>
                        <span className="text-sm text-blueGray-400">Videos</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <h3 className="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    {user?.name}
                  </h3>
                  <div className="text-base leading-normal mt-0 mb-2 text-blueGray-400 font-semibold">
                    @{user?.username}
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        {user?.desc}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
            }
          </div>
        </div >
      </div>
    </>
  );
}
