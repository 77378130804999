import { Navigate, useParams } from "react-router-dom";
import md5 from "md5";
import serverConf from "config/server.config";

const RefWrapper = () => {
    const { uname, ref } = useParams();
    let validRef = false;

    function checkRef(uname, ref) {
        let hash = md5(uname.toLowerCase() + serverConf.REF_TOKEN);
        const lastThreeChars = hash.slice(-3);

        if (lastThreeChars === ref) {
            localStorage.setItem("ref", uname.toLowerCase());
            const savedRef = localStorage.getItem("ref");
            return true;
        } else {
            return false;
        }
    }

    validRef = checkRef(uname, ref);

    return validRef ? (
        <Navigate to={"/" + uname} />
    ) : (
        <Navigate to={"/auth/login"} />
    );
};

export default RefWrapper;