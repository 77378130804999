import React, { useEffect, useState, useRef } from 'react';
import profileImage from "assets/img/team-2-800x800.jpg";
import LockedPrice from '../ui/LockedPrice';
import PlayButton from '../ui/PlayButton';
import serverConf from 'config/server.config';
import userService from 'services/user.service';
import authService from 'services/auth.service';
import RegisterDialog from '../Dialogs/RegisterDialog';
import SubDialog from '../Dialogs/SubDialog';
import Paywall from '../Paywall/Paywall';
import VideoPlayer from '../videoPlayer/VideoPlayer';

const Post = ({ post }) => {
    const userId = post.user;
    const { image, video, price, caption, postType, createdAt } = post;
    const [user, setUser] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [unlocked, setUnlocked] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [dateText, setDateText] = useState(null);
    const [showRegisterDialog, setShowRegisterDialog] = useState(false);
    const [showPaywall, setShowPaywall] = useState(false);
    const [showSubDialog, setShowSubDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser())
    const [fanBundle, setFanBundle] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const containerRef = useRef(null);

    let iconClass = '';
    let colorClass = '';

    // Determine the icon and color based on postType
    switch (postType) {
        case 'ppv':
            iconClass = 'fas fa-dollar-sign';
            colorClass = 'bg-yellow-200 text-yellow-600';
            break;
        case 'free':
            iconClass = 'fas fa-lock-open';
            colorClass = 'bg-emerald-200 text-emerald-600';
            break;
        case 'fans':
            iconClass = 'fas fa-crown';
            colorClass = 'bg-purple-200 text-purple-600';
            break;
        default:
            break;
    }

    const imageClick = () => {
        if (unlocked) {
            setShowImageModal(true);
        } else {
            if (currentUser === null) {
                setShowRegisterDialog(true);
            } else {
                if (postType === "fans") {
                    setShowSubDialog(true);
                } else {
                    //open paywall (only if user is fan?)
                    setShowPaywall(true);
                }
            }
        }
    }

    const openRegisterDialog = () => {
        setShowRegisterDialog(true);
    }

    const closeRegisterDialog = () => {
        setShowRegisterDialog(false);
    }

    const closeSubDialog = () => {
        setShowSubDialog(false);
    }

    const openPaywall = (selectedBundle) => {
        setFanBundle(selectedBundle);
    }

    const closePaywall = () => {
        setShowPaywall(false);
        setFanBundle(null);
    }

    const closeImageModal = () => {
        setShowImageModal(false);
    }

    useEffect(() => {
        if (fanBundle !== null) {
            setShowPaywall(true);
        }
    }, [fanBundle])

    useEffect(() => {
        if (image) {
            setImageUrl(serverConf.HOST + serverConf.IMAGES_ROUTE + image);
            if (image === "locked.jpg") {
                setUnlocked(false);
            } else {
                setUnlocked(true);
            }
        } else {
            setUnlocked(true);
        }
    }, [image]);

    useEffect(() => {
        if (video) {
            if (video.width) {
                setAspectRatio(video.width / video.height);
            }
        }
    }, [video]);

    useEffect(() => {
        const oneHourInMillis = 60 * 60 * 1000; // number of milliseconds in an hour
        const oneDayInMillis = 24 * oneHourInMillis; // number of milliseconds in a day
        const oneWeekInMillis = 7 * oneDayInMillis; // number of milliseconds in a week
        const oneYearInMillis = 365 * oneDayInMillis; // number of milliseconds in a year
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const date = new Date(createdAt);
        const now = new Date(); // get the current date and time

        if (now - date > oneWeekInMillis) {
            const month = months[date.getMonth()];
            if (now - date > oneYearInMillis) {
                const year = date.getFullYear();
                setDateText(`${month} ${year}`);
            } else {
                const dayOfMonth = date.getDate();
                setDateText(`${dayOfMonth} ${month}`)
            }
        } else {
            if (now - date < oneDayInMillis) {
                if (now - date < oneHourInMillis) {
                    const minutes = Math.floor((now - date) / 60000);
                    if (minutes === 0) {
                        setDateText("Just now");
                    } else {
                        setDateText(`${minutes} minutes ago`);
                    }
                } else {
                    const hours = Math.floor((now - date) / 3600000);
                    setDateText(`${hours} hours ago`);
                }
            } else {
                const dayOfWeekName = weekdays[date.getDay()];
                const options = { hour: "2-digit", minute: "2-digit" };
                const hourText = date.toLocaleTimeString([], options);
                setDateText(`${dayOfWeekName} ${hourText}`);
            }
        }
    }, [createdAt]);

    useEffect(() => {
        userService.getUserById(userId)
            .then(response => {
                setUser(response.data.user);
                if (response.data.user.avatar !== undefined) {
                    setAvatar(serverConf.HOST + serverConf.IMAGES_ROUTE + response.data.user.avatar);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [userId]);


    return (
        <>
            {showRegisterDialog ? (
                <RegisterDialog user={user} action={'subscribe'} closeRegisterDialog={closeRegisterDialog} />
            ) : (null)}
            {showSubDialog ? (
                <SubDialog user={user} closeSubDialog={closeSubDialog} openRegisterDialog={openRegisterDialog} openPaywall={openPaywall} />
            ) : (null)}
            {showPaywall ? (
                <Paywall fromUsername={currentUser?.username} type={postType} bundle={fanBundle} id={post._id} user={user} closePaywall={closePaywall} />
            ) : (null)}
            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-4 mt-32">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                        <div className="md:px-6">
                            <div className="pt-2 pb-4 md:py-10 border-blueGray-200 text-center flex flex-wrap">
                                <div className="w-6/12 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <img
                                        src={avatar || profileImage}
                                        className="h-12 w-12 bg-white rounded-full border fit-cover"
                                        alt="..."
                                    />
                                    <div className="flex flex-col ml-3">
                                        <a
                                            href={"/" + user?.username}
                                            className="text-base font-bolt text-sky-600"
                                        >
                                            {user?.name}
                                        </a>
                                        <span
                                            className="font-bold text-blueGray-600"
                                        >
                                            @{user?.username}
                                        </span>
                                    </div>
                                </div>
                                <div className="w-6/12 self-center flex justify-end items-center px-4 text-sm leading-normal text-blueGray-400 font-bold">
                                    <i className="far fa-clock mr-2 text-lg text-blueGray-400"></i>{" "}
                                    {dateText}
                                </div>
                                <div className="px-6">
                                    <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded ${colorClass}`}>
                                        <i className={`${iconClass} text-xs mr-1`}></i> {postType}
                                    </span>
                                </div>
                                <p className="px-6 w-full text-left mt-6 mb-4 text-lg leading-relaxed text-blueGray-700">
                                    {caption}
                                </p>
                                <div
                                    ref={containerRef}
                                    className="w-full mx-auto px-4 pt-2 md:pt-0 relative cursor-pointer"
                                    onClick={imageClick}
                                >
                                    {imageUrl &&
                                        <img
                                            alt="..."
                                            className="max-w-full w-full max-h-80vh object-contain mx-auto"
                                            src={imageUrl}
                                        />
                                    }
                                    {video?.url &&
                                        <VideoPlayer
                                            video={video}
                                            aspectRatio={aspectRatio}
                                            maxWidth={containerRef.current?.clientWidth}
                                        />
                                    }
                                    {!unlocked &&
                                        <>
                                            <LockedPrice price={price} type={postType} />
                                            {video?.videoId &&
                                                <PlayButton />
                                            }
                                        </>
                                    }
                                </div>
                                {showImageModal && (
                                    <>
                                        <div
                                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                            onClick={closeImageModal}
                                        >
                                            <div className="relative w-full h-full p-4 max-h-screen">
                                                {/* Content */}
                                                <img
                                                    alt="..."
                                                    className="h-full w-full object-contain mx-auto"
                                                    src={imageUrl}
                                                />
                                            </div>
                                        </div>
                                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Post;