import React from "react";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import SubBlock from "components/Own/SubBlock";

import profileImage from "assets/img/team-2-800x800.jpg";
import PreviewText from "components/Own/ui/PreviewText";
import FollowButton from "components/Own/ui/FollowButton";
import PostList from "components/Own/Post/PostList";

export default function Profile({ profileData }) {
  const { user, isUser, isCreator } = profileData || {};
  const showStats = false;

  return (
    <>
      <AdminNavbar />
      <main className="profile-page min-h-screen flex flex-col">
        <section className="relative block h-500-px bg-blueGray-200">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: user?.banner ? `url(${user.bannerUrl})` : "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <div className="flex justify-center -my-16 ">
                        <img
                          alt="..."
                          src={user.avatarUrl || profileImage}
                          className="aspect-1 shadow-xl h-auto align-middle border-none"
                          style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            maxWidth: '150px',
                            maxHeight: '150px'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-20 sm:mt-0 md:block flex justify-center">
                      <FollowButton user={user} isUser={isUser} />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    {showStats &&
                      <div className="flex justify-center py-4 lg:pt-4 pt-8">
                        <div className="mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {user.numPosts}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Posts
                          </span>
                        </div>
                        <div className="mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {user.numPhotos}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Photos
                          </span>
                        </div>
                        <div className="lg:mr-4 p-3 text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                            {user.numVideos}
                          </span>
                          <span className="text-sm text-blueGray-400">
                            Videos
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="text-center mt-0 md:mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                    {user.name || user.username}
                  </h3>
                  <div className="text-xl leading-normal mt-0 mb-2 text-blueGray-400 font-bold">
                    @{user.username}
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <PreviewText text={user.desc} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative bg-blueGray-200 flex flex-grow">
          {isCreator &&
            <>
              <div className="container mx-auto px-4 py-16 mt-32">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                  <div className="md:px-6">
                    <div className="py-10 border-blueGray-200 text-center">
                      <SubBlock user={user} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </section>
        {isCreator &&
          <PostList userPosts={profileData.posts} />
        }
      </main>
    </>
  );
}
