import React, { useState } from 'react';
import DialogHeader from './DialogHeader';
import SubBlock from '../SubBlock';

const SubDialog = ({ user, closeSubDialog, openRegisterDialog, openPaywall }) => {

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={closeSubDialog}
            >
                <div className="relative w-auto my-6 mx-10_100 max-w-3xl">
                    {/*content*/}
                    <div className="border-0 px-8 pb-8 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <DialogHeader user={user} />
                        <div className="absolute top-0 right-0 mt-4 mr-4">
                            <button className="text-blueGray-500 background-transparent font-bold uppercase p-2 text-base outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={closeSubDialog}
                            >
                                X
                            </button>
                        </div>
                        {/*body*/}
                        <SubBlock
                            user={user}
                            openRegisterDialog={openRegisterDialog}
                            openPaywall={openPaywall}
                        />
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default SubDialog;