import React, { useEffect, useState } from "react";

import userService from "services/user.service";
import tokenService from "services/token.service";

// components

export default function CardSettings({ user, update }) {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [lnAddress, setLnAddress] = useState(user?.lnAddress);
  const [price, setPrice] = useState(user?.price);
  const [pricePPV, setPricePPV] = useState(user?.pricePPV);
  const [desc, setDesc] = useState(user?.desc);
  const [successMessage, setSuccessMessage] = useState('');
  const [serverMessage, setServerMessage] = useState('');
  const [lnError, setLnError] = useState('');
  const [isCreator, setIsCreator] = useState(false);

  const handleSaveChanges = () => {
    setLnError('');
    setShowConfirmPassword(true);
  };

  const closeConfirmPassword = () => {
    setPassword("");
    setShowConfirmPassword(false);
  };

  const saveChanges = () => {
    userService.updateUser(
      user?.username,
      name,
      email,
      lnAddress,
      desc,
      price,
      pricePPV,
      password,
      newPassword
    ).then(
      response => {
        if (response.status === 200) {
          update();
          setSuccessMessage('Changes saved successfully!');
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
          tokenService.updateUser(response.data.user);
          window.location.reload();
        }
      },
      error => {
        console.log(error);
        if (error.response.status === 400) {
          update();
          setServerMessage('Error: ' + error.response.data.message);
          setLnError(error.response.data.message);
          setTimeout(() => {
            setServerMessage('');
          }, 3000);
        }
      }
    );
    closeConfirmPassword();
  };

  useEffect(() => {
    if (user?.roles.includes("ROLE_CREATOR")) {
      setIsCreator(true);
    }
  }, [user]);

  const handleModalClick = (event) => {
    event.stopPropagation(); // Stop the event from propagating to the parent container
  }

  //TODO. update user data after update

  return (
    <>
      {successMessage && (
        <div className="flex items-center fixed bottom-5 z-50 left-auto right-auto w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span className="sr-only">Check icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">{successMessage}</div>
        </div>
      )}
      {serverMessage && (
        <div className="flex items-center fixed bottom-5 z-50 left-auto right-auto w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
            </svg>
            <span className="sr-only">Error icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">{serverMessage}</div>
        </div>
      )}
      {showConfirmPassword && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={closeConfirmPassword}
          >
            <div className="relative w-auto my-6 mx-10_100 max-w-3xl"
              onClick={handleModalClick}
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="p-6 px-6 flex flex-col">
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        current password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Current Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className=" w-full mt-2 flex justify-center">
                    <button
                      className="w-full uppercase bg-sky-500 hover:bg-sky-600 active:bg-sky-600 text-white font-bold hover:shadow-lg shadow text-base px-12 py-2 rounded outline-none focus:outline-none sm:mr-2 my-2 ease-linear transition-all duration-150"
                      type="button"
                      onClick={saveChanges}
                    >
                      Confirm Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">My Profile</h6>
            <button
              className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSaveChanges}
            >
              Save Changes
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    style={{ opacity: 0.6, cursor: "not-allowed", backgroundColor: "#f5f5f5" }}
                    defaultValue={user?.username}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Repeat Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            {isCreator && (
              <>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Price Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-4/12 px-2 md:px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Subscription
                      </label>
                      <div className="relative inline-flex align-middle w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="w-5 h-5 text-gray-500 dark:text-gray-400 fas fa-dollar-sign flex items-center jusitfy-center"></i> { }
                        </div>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 pl-10 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          min={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-2 md:px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Default PPV Price
                      </label>
                      <div className="relative inline-flex align-middle w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <i className="w-5 h-5 text-gray-500 dark:text-gray-400 fas fa-dollar-sign flex items-center jusitfy-center"></i> { }
                        </div>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 pl-10 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={pricePPV}
                          onChange={(e) => setPricePPV(e.target.value)}
                          min={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-2 md:px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Lightning Address
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter to enable autoPay"
                        value={lnAddress}
                        onChange={(e) => setLnAddress(e.target.value)}
                      />
                      {lnError && (
                        <div className="text-red-500 text-sm mt-1">
                          {lnError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </>
            )}

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              About Me
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-2 md:px-4">
                <div className="relative w-full mb-3">
                  <textarea
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
