import React, { useEffect, useState } from 'react';

const NumberAnimation = ({ startNum, endNum }) => {
  const [value, setValue] = useState(startNum);

  useEffect(() => {
    let timeout;

    // Delay for 2 seconds before starting the count-up animation
    timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setValue((prevValue) => {
          const newValue = prevValue + 1;
          if (newValue === endNum) {
            clearInterval(interval);
          }
          return newValue;
        });
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }, 1000); // 000 milliseconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const numberStyle = {
    transition: '1s ease-in-out',
    color: '#FF825C',
    fontWeight: '800',
    display: 'flex',
  };

  return <div className='text-9xl' style={numberStyle}>{value}%</div>;
};

export default NumberAnimation;