import io from 'socket.io-client';
import TokenService from './token.service';
import api from "./api";
import config from '../config/server.config';

let socket = null;

export const connectSocket = () => {
    socket = io(config.HOST, {
        transportOptions: {
            polling: {
                extraHeaders: {
                    "x-access-token": TokenService.getLocalAccessToken()
                }
            }
        }
    });

    socket.emit('join'); // send join event to server
    return socket;
}

export const getSocket = () => {
    return socket;
}

export const renewToken = async () => {
    const rs = await api.post("/auth/refreshtoken", {
        refreshToken: TokenService.getLocalRefreshToken(),
    });

    const { accessToken } = rs.data;
    TokenService.updateLocalAccessToken(accessToken);
}