import React, { useEffect, useState } from 'react';
import profileImage from "assets/img/team-2-800x800.jpg";
import serverConf from 'config/server.config';

const CreatePost = ({ post, handleUpload, uploadProgress }) => {
    const { image, video, user } = post;

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [postType, setPostType] = useState('fans');
    const [iconClass, setIconClass] = useState('');
    const [colorClass, setColorClass] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [price, setPrice] = useState(user?.pricePPV);
    const [isLoading, setIsLoading] = useState(false);
    const [caption, setCaption] = useState('');

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handlePriceChange = (event) => {
        const inputValue = event.target.value;
        // Regular expression to match the format "0.00"
        const regex = /^\d+(\.\d{0,2})?$/;

        if (regex.test(inputValue) || inputValue === '') {
            setPrice(inputValue);
        }
    };

    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
    };

    useEffect(() => {
        if (user?.avatar) {
            setAvatarUrl(serverConf.HOST + serverConf.IMAGES_ROUTE + user?.avatar);
        }
    }, [user]);

    useEffect(() => {
        // Determine the icon and color based on postType
        switch (postType) {
            case 'ppv':
                setIconClass('fas fa-dollar-sign');
                setColorClass('text-yellow-600');
                break;
            case 'free':
                setIconClass('fas fa-lock-open');
                setColorClass('text-emerald-600');
                break;
            case 'fans':
                setIconClass('fas fa-crown');
                setColorClass('text-purple-600');
                break;
            default:
                break;
        }
    }, [postType]);

    function selectType(type) {
        setPostType(type);
        setDropdownOpen(false);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        handleUpload(caption, postType, price);
    };

    return (
        <>
            <div className="flex flex-grow">
                <section className="w-full relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4 mt-32 pt-48">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-6 py-10 flex flex-col justify-center">
                                <button
                                    disabled={isLoading}
                                    type="button"
                                    onClick={handleSubmit}
                                    className="w-1/3 mx-auto text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center justify-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                >
                                    {isLoading ? (
                                        <>
                                            <svg
                                                aria-hidden="true"
                                                role="status"
                                                className="inline w-4 h-4 mr-3 text-white animate-spin"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="#E5E7EB"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            {uploadProgress ? (
                                                <>{uploadProgress.toFixed(1)}%</>
                                            ) : (
                                                <>Uploading...</>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <i className="fas fa-plus flex justify-center w-5 mr-2 text-sm text-white"></i>
                                            {"Post"}
                                        </>
                                    )}
                                </button>
                                <div className="border-blueGray-200 text-center flex flex-wrap">
                                    <div className="w-6/12 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                        <img
                                            src={avatarUrl || profileImage}
                                            className="h-12 w-12 bg-white rounded-full border"
                                            alt="..."
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div className="flex flex-col ml-3">
                                            <a
                                                href={"/" + user?.username}
                                                className="text-base font-bolt text-sky-600"
                                            >
                                                {user?.name}
                                            </a>
                                            <span
                                                className="font-bold text-blueGray-600"
                                            >
                                                @{user?.username}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-6/12 self-center flex justify-end items-center px-4 text-sm leading-normal text-blueGray-400 font-bold">
                                        <form>
                                            <div className="flex relative justify-end">
                                                <button
                                                    id="dropdown-button"
                                                    className={`relative flex-shrink-0 z-10 inline-flex items-center py-2.5 px-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 dark:border-gray-700 dark:text-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 
                                                    ${isDropdownOpen ? 'bg-gray-200' : ''}
                                                    ${postType === 'ppv' ? 'rounded-l-lg' : 'rounded-lg'}`}
                                                    type="button"
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className={`text-sm inline-flex items-center uppercase ${colorClass}`}>
                                                        <i className={`${iconClass} w-5 mr-1`}></i>
                                                        <span className="pl-1 pr-2">
                                                            {postType}
                                                        </span>
                                                    </span>
                                                    <svg
                                                        aria-hidden="true"
                                                        className={`w-4 h-4 ml-1 transition-transform duration-300 transform ${isDropdownOpen ? 'rotate-180' : ''
                                                            }`}
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                            clipRule="evenodd"
                                                        ></path>
                                                    </svg>
                                                    <div
                                                        id="dropdown"
                                                        className={`absolute left-0 top-full mt-2 z-10 ${isDropdownOpen ? 'block' : 'hidden'
                                                            } bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700`}
                                                    >
                                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                                                            <li>
                                                                <button type="button" className="inline-flex w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                    onClick={() => selectType('fans')}
                                                                >
                                                                    <div className="inline-flex items-center text-purple-600 text-sm uppercase">
                                                                        <i className="w-5 fas fa-crown mr-1"></i> { }
                                                                        Fans
                                                                    </div>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type="button" className="inline-flex w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                    onClick={() => selectType('ppv')}
                                                                >
                                                                    <div className="inline-flex items-center text-yellow-600 text-sm uppercase">
                                                                        <i className="w-5 fas fa-dollar-sign mr-1"></i> { }
                                                                        PPV
                                                                    </div>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type="button" className="inline-flex w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                                                    onClick={() => selectType('free')}
                                                                >
                                                                    <div className="inline-flex items-center text-emerald-600 text-sm uppercase">
                                                                        <i className="w-5 fas fa-lock-open mr-1"></i> { }
                                                                        Free
                                                                    </div>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </button>
                                                {postType === 'ppv' &&
                                                    <div className="relative w-1/3">
                                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                            <i className="w-5 h-5 text-gray-500 dark:text-gray-400 fas fa-dollar-sign flex items-center jusitfy-center"></i> { }
                                                        </div>
                                                        <input
                                                            type="search"
                                                            id="search-dropdown"
                                                            className="block pl-10 p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                                            placeholder="0.00"
                                                            min={1}
                                                            value={price}
                                                            onChange={handlePriceChange}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                    <div className="relative z-0 w-full mb-3 px-4 group">
                                        <textarea
                                            type="caption"
                                            name="floating_caption"
                                            id="floating_caption"
                                            rows="2"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder="Write something for your fans..."
                                            value={caption}
                                            onChange={handleCaptionChange}
                                        />
                                    </div>
                                    <div
                                        className="w-full mx-auto px-4 pt-2 md:pt-0 relative"
                                    >
                                        {image &&
                                            <img
                                                alt="..."
                                                className="max-w-full w-full mx-auto rounded-lg shadow-xl"
                                                src={image}
                                            />
                                        }
                                        {video &&
                                            <video
                                                src={video}
                                                className="max-w-full w-full mx-auto rounded-lg shadow-xl"
                                                controls autoPlay playsInline width="100%"
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
};

export default CreatePost;