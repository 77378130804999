import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import UserService from "services/user.service";
import AuthService from "services/auth.service";
import serverConf from "config/server.config";

import Profile from "views/Profile";

export default function ProfilePage({ paymentReceived }) {

    const [update, setUpdate] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [userLoaded, setUserLoaded] = useState(false);
    const [postsLoaded, setPostsLoaded] = useState(false);
    const [profile, setProfile] = useState({
        user: {},
        hasPosts: false,
        posts: [],
        isCreator: false,
        isUser: false,
        ownPage: false,
        isFollower: false
    });
    const [profileData, setProfileData] = useState();

    const { uname } = useParams();
    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const navigate = useNavigate();

    useEffect(() => {
        UserService.getUser(uname)
            .then(response => {
                const userData = response.data.user;
                setProfile(profile => ({
                    ...profile,
                    user: userData
                }));
            })
            .catch(error => {
                console.error(error);
                navigate("/404");
            })
            .finally(() => {
                setUserLoaded(true);
            });

        UserService.getUserPosts(uname)
            .then(response => {
                if (response.data.posts.length > 0) {
                    setProfile(profile => ({
                        ...profile,
                        hasPosts: true
                    }));
                }
                const userPosts = response.data.posts;
                setProfile(profile => ({
                    ...profile,
                    posts: userPosts
                }));
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setPostsLoaded(true);
            });
    }, [uname, update]);

    useEffect(() => {
        if (Object.keys(profile.user).length !== 0 && !loaded) {
            if (profile.user.roles.includes("ROLE_CREATOR")) {
                setProfile(profile => ({
                    ...profile,
                    isCreator: true
                }));
            }

            if (currentUser !== null) {
                setProfile(profile => ({
                    ...profile,
                    isUser: true,
                    ownPage: currentUser.username === profile.user.username
                }));
            } else {
                setProfile(profile => ({
                    ...profile,
                    isFollower: false,
                    isUser: false,
                    ownPage: false
                }));
            }

            resolverUrls(
                profile.user.banner,
                profile.user.banner_thumbnail,
                profile.user.avatar,
                profile.user.avatar_thumbnail
            );
            setLoaded(userLoaded && postsLoaded);
        }
    }, [userLoaded, postsLoaded]);


    useEffect(() => {
        if (loaded) {
            setProfileData(profile);
        }
    }, [loaded]);

    const resolverUrls = (bannerName, bannerThumbName, avatarName, avatarThumbName) => {
        const defAvatar = "//ssl.gstatic.com/accounts/ui/avatar_2x.png";
        var avatar = avatarName ? serverConf.HOST + serverConf.IMAGES_ROUTE + avatarName : defAvatar;
        var avatarThumbnail = avatarThumbName ? serverConf.HOST + serverConf.IMAGES_ROUTE + avatarThumbName : avatar;
        profile.user.avatarUrl = avatar;
        profile.user.avatarThumbUrl = avatarThumbnail;

        var defBanner = "https://via.placeholder.com/800x400"
        var banner = bannerName ? serverConf.HOST + serverConf.IMAGES_ROUTE + bannerName : defBanner;
        var bannerThumbnail = bannerThumbName ? serverConf.HOST + serverConf.IMAGES_ROUTE + bannerThumbName : banner;
        profile.user.bannerUrl = banner;
        profile.user.bannerThumbUrl = bannerThumbnail;
    }

    useEffect(() => {
        if (paymentReceived) {
            window.location.reload(true);
        }
    }, [paymentReceived]);

    return (
        <>
            {profileData !== undefined &&
                <Profile profileData={profileData} />
            }
        </>
    );
}
