import React, { useEffect } from "react";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

export default function DashboardLayout({ payoutReceived, children }) {

    useEffect(() => {
        if (payoutReceived) {
            window.location.reload(true);
        }
    }, [payoutReceived]);

    return (
        <>
            <div className="relative bg-blueGray-100 min-h-screen">
                <AdminNavbar />
                {/* Header */}
                <HeaderStats />
                <div className="max-w-1800-px px-4 md:px-10 mx-auto w-full -m-24">
                    {children}
                    <FooterAdmin />
                </div>
            </div>
        </>
    );
}
