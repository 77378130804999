/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backgroundImage from 'assets/img/pattern_react.png';
import backgroundImages from 'assets/img/Cover.webp'

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";
import NumberGoesUp from "components/Own/ui/NumberGoesUp";
import rocketImage from "assets/img/product-launch.svg";
import bitcoinImage from "assets/svg/undraw_digital_currency_qpak.svg";
import BitcoinHelper from "components/Own/BitcoinHelper/BitcoinHelper";
import FaqSection from "components/Own/FAQ/FaqSection";
import Comparer from "components/Own/BitcoinHelper/Comparer";
import Register from "./auth/Register";
import AgePopup from "components/Own/ui/AgePopup";

export default function Index() {

  const [showPopup, setShowPopup] = useState(true);
  const [showBanner, setShowBanner] = useState(true);

  function closePopup(adult) {
    if (adult === true) {
      setShowPopup(false);
      localStorage.setItem('age', 'adult');
    } else {
      window.location.href = "https://www.google.com";
    }
  }

  function closeBanner() {
    setShowBanner(false);
  }

  useEffect(() => {
    if (localStorage.getItem('age') === 'adult') {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = showPopup ? 'hidden' : 'auto';
  }, [showPopup]);

  return (
    <>
      {showPopup ? (
        <AgePopup closePopup={closePopup} />
      ) : (
        <>
          <AdminNavbar fixed />
          {showBanner && (
            <div id="banner" tabIndex="-1" className="flex fixed z-50 justify-center items-start py-2 px-4 md:px-16 w-full bg-gray-50 border border-b border-gray-200 sm:items-center dark:border-gray-700 dark:bg-gray-800">
              <div className="container flex flex-row">
                <p className="flex flex-col md:flex-row  text-sm w-full font-light text-gray-500 dark:text-gray-400">
                  <span className="font-bold text-base text-red-700 flex items-center" >
                    {"✨ Early bird limited offer! 🐦 "}
                  </span>
                  <span className="text-base flex items-center md:ml-4" >
                    {"Join now and get 95% of your content earnings. Only for a limited time! "}
                  </span>
                  <a className="font-medium text-blue-500 text-sm md:ml-4 dark:text-blue-500 no-underline flex items-center" href="/auth/register">
                    Become a creator now
                    <i className="fa fa-arrow-right ml-2 leading-relaxed"></i></a>
                </p>
                <button onClick={closeBanner} type="button" className="flex items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      <section className="header relative pt-16 items-center flex flex-col md:flex-row h-screen">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${backgroundImages})`,
            backgroundSize: "cover",
            filter: "brightness(0.4)",
          }}
        ></div>
        <div className="container z-4 mx-auto overflow-hidden flex flex-row h-full">
          <div className="container z-2 px-4 w-full md:w-6/12 items-center flex flex-wrap h-full">
            <div className="pb-6">
              <h2 className="font-semibold text-5xl lg:text-7xl text-white">
                Up to <NumberGoesUp startNum={80} endNum={95} /> of your content earnings
              </h2>
              <p className="mt-4 text-xl leading-relaxed text-blueGray-200">
                Get more out of your content. Extremely low fees, instant payouts, and no minimum amounts. Start earning (literally) today!
              </p>
              <div className="pt-12 mb-12">
                <a
                  href="/auth/register"
                  className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-sky-500 active:bg-sky-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Join Now
                </a>
                <a
                  href="#compare"
                  className="ml-1 text-white border border-solid border-white hover:text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-transparent active:bg-blueGray-600 uppercase text-sm hover:shadow ease-linear transition-all duration-150"
                >
                  Learn more
                </a>
              </div>
              <a
                href="/discover"
                className="ml-1 font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-200 text-emerald-600 hover:text-emerald-500 uppercase text-sm hover:shadow ease-linear transition-all duration-150"
              >
                <i className="fa fa-lock-open mr-2"></i>
                Check out the free content feed!
              </a>
            </div>
          </div>

          <div className="relative w-full h-full md:w-6/12 px-4 hidden md:flex items-center justify-content">
            <div className="container z-4 mx-auto md:w-10/12">
              <Register />
            </div>
          </div>
        </div>

      </section>

      <section id="compare" className="relative bg-blueGray-100">

        <div className="container mx-auto overflow-hidden pb-20">

          <div className="flex flex-wrap items-center md:pt-32 md:pb-64">

            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto mt-8 md:mt-32">
              <h3 className="text-4xl lg:text-6xl mb-4">
                👀
              </h3>
              <h3 className="font-semibold text-4xl lg:text-6xl text-blueGray-600">
                Look how much you can earn
              </h3>
              <p className="text-xl font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Take the most out of your content. See how much you will earn with us compared to OnlyFans, Fansly and other platforms.
              </p>
            </div>

            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-4 md:mt-32">
              <div className="container mx-auto">
                <Comparer />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" pb-40 relative bg-blueGray-200">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center md:pt-32 md:pb-64">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <img alt="..." className="max-w-full" src={bitcoinImage} />
            </div>

            <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto mt-32">
              <h3 className="text-6xl mb-2 font-semibold leading-normal">
                Bitcoin? Why?
              </h3>
              <p className="text-xl font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Bitcoin is the internet's money. <br />
                Thanks to Bitcoin we can offer very low fees and send you the money instanly as your fans pay for your content.
                No need to wait for monthly payments or minimum amounts! <br />
                Bitcoin is also easy, just install an app and you are reading to start earning Bitcoin!. <br />
                Take a look below to see how easy it is to get started.
              </p>
              <div className="flex flex-col lg:flex-row">
                <a
                  href="#wallet"
                  className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
                >
                  SET UP YOUR FIRST WALLET{" "}
                  <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                </a>
                <a
                  href="#faq"
                  className="lg:ml-3 font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150 "
                >
                  FAQ{" "}
                  <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="wallet" className="pb-48 bg-blueGray-100 relative pt-16">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <BitcoinHelper />
        </div>
      </section>

      <section id="coming" className="pb-16 bg-blueGray-200 relative pt-32">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center md:pt-32 md:pb-64">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-4 mt-32">
              <ol className="relative text-gray-500 border-l border-white dark:border-gray-700 dark:text-gray-400">
                <li className="mb-12 ml-10">
                  <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-2 ring-green-500 dark:ring-gray-900 dark:bg-green-900">
                    <svg className="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                    </svg>
                  </span>
                  <h3 className="font-medium text-lg leading-tight">Subscriptions</h3>
                  <p className="text-sm">Follow and get content from your favourite creators</p>
                </li>
                <li className="mb-12 ml-10">
                  <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-2 ring-green-500 dark:ring-gray-900 dark:bg-green-900">
                    <svg className="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                    </svg>
                  </span>
                  <h3 className="font-medium text-lg leading-tight">PPV Content</h3>
                  <p className="text-sm">Upload content under a paywall</p>
                </li>
                <li className="mb-12 ml-10">
                  <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <i className="fas fa-comments"></i>
                  </span>
                  <h3 className="font-medium text-lg leading-tight">Chat</h3>
                  <p className="text-sm">Chat, send tips and get custom content from the creators you love</p>
                </li>
                <li className="ml-10">
                  <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <i className="fas fa-heart"></i>
                  </span>
                  <h3 className="font-medium text-lg leading-tight">Comments and reactions</h3>
                  <p className="text-sm">Interact with comments or reactions to your favourite posts</p>
                </li>
              </ol>

            </div>

            <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto mt-32">
              <h3 className="text-5xl mb-2 font-semibold leading-normal">
                A lot more is coming
              </h3>
              <p className="text-xl font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                The next weeks are going to be very busy. <br />
                We are already developing and testing a handful of new features that will make your experience even better. <br />
                You can see the most relevant ones on the timeline. <br />
                We want to hear from you, if you are missing a feature that you love, tell us and we will make our best to bring it to you as soon as possible! <br />
              </p>
              <div className="flex flex-col lg:flex-row">
                <a
                  href="mailto:support@onlysxy.com"
                  className="font-bold text-lg text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
                >
                  SUPPORT@ONLYSXY.COM{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="bg-white dark:bg-gray-900">
        <div className="py-8 mb-16 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">FAQs</h2>
          <FaqSection />
        </div>
      </section>

      <Footer />
    </>
  );
}
