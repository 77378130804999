import React from 'react';

const LockedPrice = ({ price, type }) => {
    return (
        <button
            style={{
                transform:
                    "translate(-50%, -50%)",
            }}
            className="z-10 absolute top-50 left-50 bg-black-50 hover:bg-black text-white rounded-lg py-2 px-4 font-bold cursor-pointer flex items-center justify-center">
            <i className="fas fa-lock mr-2 text-lg "></i>{" "}
            {type === 'ppv' ? `Unlock for $${price}` : 'Subscribe to unlock'}
        </button>
    );
};

export default LockedPrice;