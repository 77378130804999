import React, { useState, useEffect } from "react";
import authService from "services/auth.service";
import userService from "services/user.service";
import converterService from "services/converter.service";
import { useNavigate } from 'react-router-dom';

// components
import CardStatsSimple from "components/Cards/CardStatsSimple";
import PayOut from "components/Own/Paywall/PayOut";

export default function HeaderStats() {
  const navigate = useNavigate();
  const [enableWithdraw, setEnableWithdraw] = useState(true);
  const [enableFollowers, setEnableFollowers] = useState(true);
  const [enableFans, setEnableFans] = useState(true);
  const [walletValue, setWalletValue] = useState("0 sats");
  const [walletValueUSD, setWalletValueUSD] = useState("$0.00");
  const [walletText, setWalletText] = useState("Click to withdraw");
  const [earningsValue, setEarningsValue] = useState("0 sats");
  const [earningsValueUSD, setEarningsValueUSD] = useState("$0.00");
  const [followersValue, setFollowersValue] = useState("0");
  const [followersTitle, setFollowersTitle] = useState('FOLLOWERS');
  const [fansValue, setFansValue] = useState("0");
  const [fansTitle, setFansTitle] = useState('FANS');
  const [showPayOut, setShowPayOut] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  const loadDashboard = () => {
    userService.loadDashboard()
      .then(response => {
        prepareWallet(response.data.wallet);
        prepareStats(response.data.stats);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    loadDashboard();
  }, []);

  const sats2USD = async (sats) => {
    if (sats <= 0) {
      return 0;
    } else {
      const rate = await converterService.getRate();
      return sats / rate;
    }
  };

  const prepareWallet = async (wallet) => {
    setWalletValue(wallet.sats.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " sats");
    const valueUSD = await sats2USD(wallet.sats);
    setWalletValueUSD('~ $' + valueUSD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    if (wallet.autoPay) {
      setWalletText("Delete Lightning Address to disable autoPay");
    } else {
      setWalletText("Click to withdraw - autoPay disabled");
    }
    if (wallet.sats <= 0) {
      setEnableWithdraw(false);
      setWalletText("No funds to withdraw");
    }
  };

  const prepareStats = async (stats) => {
    const earnings = stats.totalEarnings;
    setEarningsValue(earnings.toLocaleString(undefined, { maximumFractionDigits: 0 }) + " sats");
    const valueUSD = await sats2USD(earnings);
    setEarningsValueUSD('~ $' + valueUSD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    if (stats.hasOwnProperty('numFollowers')) {
      setIsCreator(true);
      setFollowersTitle("FOLLOWERS");
      const numFollowers = stats.numFollowers;
      if (numFollowers <= 0) {
        setEnableFollowers(false);
      }
      setFollowersValue(numFollowers.toLocaleString(undefined, { maximumFractionDigits: 0 }));
    } else {
      setIsCreator(false);
      setFollowersTitle("FOLLOWING");
      const numFollowing = stats.numFollowing;
      if (numFollowing <= 0) {
        setEnableFollowers(false);
      }
      setFollowersValue(numFollowing.toLocaleString(undefined, { maximumFractionDigits: 0 }));
    }
    if (stats.hasOwnProperty('numFans')) {
      setIsCreator(true);
      setFansTitle("FANS");
      const numFans = stats.numFans;
      if (numFans <= 0) {
        setEnableFans(false);
      }
      setFansValue(numFans.toLocaleString(undefined, { maximumFractionDigits: 0 }));
    } else {
      setIsCreator(false);
      setFansTitle("SUBSCRIPTIONS");
      const numSubscriptions = stats.numSubscriptions;
      if (numSubscriptions <= 0) {
        setEnableFans(false);
      }
      setFansValue(numSubscriptions.toLocaleString(undefined, { maximumFractionDigits: 0 }));
    }
  };

  const openWallet = () => {
    setShowPayOut(true);
  };

  const closePayOut = () => {
    setShowPayOut(false);
  };

  const showUsers = (name) => {
    navigate('/users', { state: { selectedComponent: name } });
  };

  const showHistory = () => {
    navigate('/history');
  };

  return (
    <>
      {showPayOut ? (
        <PayOut closePayOut={closePayOut} />
      ) : (null)}
      {/* Header */}
      <div className="relative bg-blueGray-300  pb-32 pt-12 md:pt-16">
        <div className="px-4 mx-auto md:px-10 mx-auto w-full max-w-1800-px mt-10 pt-10 mb-4">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              {isCreator &&
                <div onClick={enableWithdraw ? openWallet : null}
                  className={`w-full lg:w-6/12 xl:w-3/12 md:px-4 ${enableWithdraw ? "cursor-pointer" : ""
                    }`}
                >
                  <CardStatsSimple
                    statSubtitle="WALLET"
                    statTitle={walletValue}
                    statText={walletText}
                    statUSD={walletValueUSD}
                    statIconName="fas fa-cash-register"
                    statIconColor="bg-red-500"
                  />
                </div>
              }
              <div onClick={showHistory}
                className={`w-full lg:w-6/12 xl:w-3/12 md:px-4 cursor-pointer`}
              >
                <CardStatsSimple
                  statSubtitle={isCreator ? "EARNINGS (TOTAL)" : ""}
                  statTitle={isCreator ? earningsValue : "Transactions"}
                  statText="Show history"
                  statUSD={isCreator ? earningsValueUSD : ""}
                  statIconName="fab fa-btc"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div
                onClick={() => enableFans ? (showUsers(isCreator ? 'numFans' : 'numSubscriptions')) : null}
                className={`w-full lg:w-6/12 xl:w-3/12 md:px-4 ${enableFans ? "cursor-pointer" : ""
                  }`}
              >
                <CardStatsSimple
                  statSubtitle={fansTitle}
                  statTitle={fansValue}
                  statText={"Show " + (isCreator ? 'fans' : 'active subscriptions')}
                  statIconName="fas fa-star"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div
                onClick={() => enableFollowers ? (showUsers(isCreator ? 'numFollowers' : 'numFollowing')) : null}
                className={`w-full lg:w-6/12 xl:w-3/12 md:px-4 ${enableFollowers ? "cursor-pointer" : ""
                  }`}
              >
                <CardStatsSimple
                  statSubtitle={followersTitle}
                  statTitle={followersValue}
                  statText={"Show " + (isCreator ? 'followers' : 'creators')}
                  statIconName="fas fa-users"
                  statIconColor="bg-sky-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
