import React, { useState, useEffect } from 'react';
import CreatePost from '../Post/CreatePost';

import authService from 'services/auth.service';
import userService from 'services/user.service';


function ImageUploader({ file }) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [post, setPost] = useState(null);

  const currentUser = authService.getCurrentUser();

  const handleFile = (file) => {
    if (file) {
      setIsLoading(true);

      const img = new Image();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        img.src = reader.result;
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const maxWidth = window.innerWidth * 0.6;
          const maxHeight = window.innerHeight * 0.6;
          let newWidth, newHeight;
          if (width > maxWidth) {
            newWidth = maxWidth;
            newHeight = (height * maxWidth) / width;
          } else {
            newWidth = width;
            newHeight = height;
          }
          if (newHeight > maxHeight) {
            newWidth = (newWidth * maxHeight) / newHeight;
            newHeight = maxHeight;
          }

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const canvasUrl = canvas.toDataURL();

          let newPost = {
            image: canvasUrl,
            user: currentUser
          };

          setImage(file);
          setPost(newPost);
          setIsLoading(false);
          setError(null);
        }
      };
    }
  };

  useEffect(() => {
    handleFile(file);
  }, [file]);

  const handleUpload = (caption, postType, price) => {
    // Use the FormData API to send the image to the server
    const formData = new FormData();
    formData.append("image", image, image.name);
    formData.append('caption', caption);
    formData.append('postType', postType);
    formData.append('price', price);
    formData.append('user', currentUser.username);

    // Make a POST request to the server with the image data
    userService.upload(formData)
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/" + currentUser.username;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {post &&
        <CreatePost post={post} handleUpload={handleUpload} />
      }
    </>
  );
}
export default ImageUploader;