import api from './api';

const URL_PREFIX = '/pay/';

class PayService {
    genInvoice(fromUsername, toUsername, type, id, bundle) {
        return api.post(URL_PREFIX + "genInvoice", {
            fromUsername,
            toUsername,
            type,
            id,
            bundle,
        });
    }

    checkPayment(payment_hash) {
        return api.get(URL_PREFIX + "checkPayment/" + payment_hash);
    }

    invoiceStatus(invoice) {
        return api.get(URL_PREFIX + "invoiceStatus/" + invoice);
    }

    payOut() {
        return api.get(URL_PREFIX + "payOut");
    }

    withdraw(paymentRequest) {
        return api.post(URL_PREFIX + "withdraw", {
            paymentRequest,
        });
    }
}

const payServiceInstance = new PayService();

export default payServiceInstance;