import React from "react";
import albyLogo from "assets/logos/alby_logo.svg";
import bipaLogo from "assets/logos/bipa_logo.png";
import bullbitcoinLogo from "assets/logos/bullbitcoin_logo.png";
import cashappLogo from "assets/logos/cash_app_logo.png";
import coincornerLogo from "assets/logos/coincorner_logo.webp";
import mtPelerinLogo from "assets/logos/mt_pelerin_logo.svg";
import peachBitcoinLogo from "assets/logos/peach_bitcoin_logo.png";


const WalletInfo = ({ props, isCreator }) => {
    const { name, isExchange, isWallet, lightning, full_kyc, kyc_light, no_kyc, lnurl, webln, url } = props;
    let logo;

    switch (name) {
        case 'Alby':
            logo = albyLogo;
            break;
        case 'Bipa':
            logo = bipaLogo;
            break;
        case 'Bull Bitcoin':
            logo = bullbitcoinLogo;
            break;
        case 'CashApp':
            logo = cashappLogo;
            break;
        case 'CoinCorner':
            logo = coincornerLogo;
            break;
        case 'Mt Pelerin':
            logo = mtPelerinLogo;
        case 'Peach Bitcoin':
            logo = peachBitcoinLogo;
        default:
            break;
    }

    return (
        <div className="mt-3 w-full lg:w-6/12">
            <a href={url} target="_blank" rel="noreferrer">
                <div className="w-full max-w-sm p-4 bg-blueGray-50 border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
                        {isExchange ? 'Exchange' : ''}
                        {isExchange && isWallet ? ' + ' : ''}
                        {isWallet ? 'Wallet' : ''}
                    </h5>
                    <div className="flex flex-col lg:flex-row items-center justify-center text-gray-900 dark:text-white">
                        <img
                            alt="..."
                            className="shadow-md rounded-full w-12 h-12 object-contain bg-white"
                            src={logo}
                        />
                        <span className="ml-3 text-gray-900 dark:text-white text-3xl font-semibold">{name}</span>
                    </div>
                    <ul role="list" className="space-y-5 my-7">
                        <li className="flex space-x-3 items-center">
                            <i className={`w-6 fas fa-check ${lightning ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                            <span className={`text-base font-normal ${lightning ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>Bitcoin Lightning</span>
                        </li>
                        {isExchange && (
                            <>
                                <li className="flex space-x-3 items-center ">
                                    <i className={`w-6 fas fa-check ${isExchange ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                                    <span className={`text-base font-normal ${isExchange ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>Buy/Sell Bitcoin</span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <i className={`w-6 fas ${full_kyc ? 'text-orange-500 dark:text-orange-500 fa-exclamation' : 'text-emerald-500 dark:text-emerald-500 fa-check'}`}></i>
                                    <span className={`text-base font-normal leading-tight text-gray-500 dark:text-gray-400`}>
                                        {full_kyc ? ('ID required') : ('ID not required')}
                                    </span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <i className={`w-6 fas fa-check ${kyc_light ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                                    <span className={`text-base font-normal ${kyc_light ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>
                                        {isCreator ? 'Sell to bank account' : 'Buy with credit card'}
                                    </span>
                                </li>
                                <li className="flex space-x-3 items-center">
                                    <i className={`w-6 fas fa-check ${no_kyc ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                                    <span className={`text-base font-normal ${no_kyc ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>
                                        Buy with cash
                                    </span>
                                </li>
                            </>
                        )}
                        {isWallet && (
                            <>
                                {isCreator ? (
                                    <li className="flex space-x-3 items-center">
                                        <i className={`w-6 fas fa-check ${lnurl ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                                        <span className={`text-base font-normal ${lnurl ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>
                                            Instant payouts
                                        </span>
                                    </li>
                                ) : (
                                    <li className="flex space-x-3 items-center">
                                        <i className={`w-6 fas fa-check ${webln ? 'text-blue-600 dark:text-blue-500' : 'text-gray-400 dark:text-gray-500'}`}></i>
                                        <span className={`text-base font-normal ${webln ? '' : 'line-through'} leading-tight text-gray-500 dark:text-gray-400`}>
                                            1-Click pay
                                        </span>
                                    </li>
                                )}
                            </>
                        )}
                    </ul>
                    <button
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                    >
                        More info
                    </button>
                </div>
            </a>
        </div>
    );
}

export default WalletInfo;