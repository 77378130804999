export default function getCroppedImg(imageFile, cropAreaPixels, compressedWidth, compressedHeigth) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    const ctx = canvas.getContext('2d');

    image.onload = function () {
      const { x, y, width, height } = cropAreaPixels;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
      canvas.toBlob((blob) => {
        const compressedBlob = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              const elem = document.createElement('canvas');
              elem.width = compressedWidth;
              elem.height = compressedHeigth;
              const ctx = elem.getContext('2d');
              ctx.drawImage(img, 0, 0, elem.width, elem.height);
              ctx.canvas.toBlob(
                (resultBlob) => {
                  resolve(resultBlob);
                },
                'image/jpeg',
                1 //quality
              );
            };
            img.onerror = (error) => {
              reject(error);
            };
          };
        });
        resolve(compressedBlob);
      }, 'image/jpeg');
    };

    image.onerror = function () {
      reject(new Error('Failed to load image'));
    };

    image.src = URL.createObjectURL(imageFile);
  });
}
