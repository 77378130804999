const FanSection = () => {
    return (
        <div className="grid pt-8 text-left md:gap-16 dark:border-gray-700 md:grid-cols-2">
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        How do I subscribe to a creator?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        Once you have activated your fan account, you just need to go to the creator's profile and click on the subscribe button. You will get a QR code that can be scanned with any wallet to pay the subscription. If you are using a wallet compatible with 1-Click-Pay you will be able to pay directly without having to scan the QR code. Once you have done that, you will be subscribed to the creator and you will be able to see their content.
                    </p>
                </div>
            </div>
            <div>
                <div className="mb-10">
                    <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>
                        What is 1-Click-Pay?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                        Some Bitcoin Lightning wallets work as browser plugins. Whenever a website requires a payment you will get a prompt from your wallet. You can then pay with a single click. This is the easiest way to pay for a subscription.
                    </p>
                    <a
                        href="https://getalby.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Alby Wallet{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                    <br />
                    <a
                        href="https://kollider.xyz/wallet"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 underline hover:text-gray-400 dark:text-gray-400 ease-linear transition-all duration-150"
                    >
                        Kollider Wallet{" "}
                        <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default FanSection;