import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

// components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import authService from "services/auth.service";

import backgroundImage from "assets/img/register_bg_2.png";
import backgroundImages from 'assets/img/Cover.webp'


export default function Auth() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());

  useEffect(() => {
    if (location.pathname === '/auth/login' ?? location.pathname === '/login') {
      setShowLogin(true);
      setShowRegister(false);
    } else if (location.pathname === '/auth/register' ?? location.pathname === '/register') {
      setShowLogin(false);
      setShowRegister(true);
    } else {
      setShowLogin(true);
      setShowRegister(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser != null) {
      authService.checkUser()
        .then((response) => {
          if (response === true) {
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [currentUser]);


  return (
    <>
      <AdminNavbar fixed />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen flex justify-center">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${backgroundImages})`,
              backgroundSize: "cover",
              filter: "brightness(0.4)",
            }}
          ></div>
          <div className="w-full md:w-4/12 max-w-580-px">
            {showLogin ?
              (<Login redirect={redirect} />)
              : null}
            {showRegister ?
              (<Register redirect={redirect} />)
              : null}
          </div>
        </section>
      </main>
    </>
  );
}
