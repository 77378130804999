import React, { useState, useRef, useEffect } from 'react';
import { requestProvider } from 'webln';
import QRCode from "qrcode";

import payService from '../../../services/pay.service';
import converterService from '../../../services/converter.service';


const PayOut = ({ closePayOut }) => {

    const [invoice, setInvoice] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [isWebln, setIsWebln] = useState(false);
    const [lnAddress, setLnAddress] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [LNurlw, setLNurlw] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountText, setAmountText] = useState("");
    const [price, setPrice] = useState(0);
    const [enableButton, setEnableButton] = useState(false);

    const canvasRef = useRef(null);

    useEffect(() => {
        checkWebLn();
        fetchLNurl();
    }, []);

    useEffect(() => {
        if (lnAddress !== "" || invoice !== "" || isWebln) {
            setEnableButton(true);
        }
    }, [isWebln, lnAddress, invoice]);

    useEffect(() => {
        if (LNurlw !== null) {
            genQR(LNurlw);
            setAmount(LNurlw.max_withdrawable);
            const formattedAmount = LNurlw.max_withdrawable.toLocaleString(undefined, { maximumFractionDigits: 0 });
            setAmountText(formattedAmount);
            tryWebLn();
        }
    }, [LNurlw]);

    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [showToast]);

    useEffect(() => {
        if (amount !== null) {
            getUsd(amount);
        }
    }, [amount]);

    const getUsd = async (amount) => {
        const rate = await converterService.getRate();
        const value = amount / rate;
        const formattedPrice = value.toLocaleString(undefined, { maximumFractionDigits: 2 });
        setPrice(formattedPrice);
    }

    const genQR = (LNurlw) => {
        QRCode.toCanvas(
            canvasRef.current,
            LNurlw.lnurl,
            {
                errorCorrectionLevel: 'L',
                width: 300,
            },
            (error) => error && console.error(error)
        );
    }

    const fetchLNurl = async () => {
        payService.payOut()
            .then(response => {
                setLNurlw(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    useEffect(() => {
        // Detect if user is on a mobile device
        setIsMobile(window.innerWidth <= 768);
    }, [window.innerWidth]);

    const checkWebLn = async () => {
        try {
            setIsWebln(typeof window.webln !== 'undefined')
        }
        catch (error) {
            setIsWebln(false);
            console.log(error);
        }
    }

    const manageWithdraw = async () => {
        if (lnAddress !== "") {
            withdrawLightningAddress();
        } else if (invoice !== "") {
            withdrawInvoice();
        } else if (isWebln) {
            tryWebLn();
        } else {
            console.log("no payment method selected");
        }
    }

    const tryWebLn = async () => {
        try {
            const webln = await requestProvider();
            if (webln) {
                withdrawWebLn(webln);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const withdrawWebLn = async (webln) => {
        await webln.makeInvoice({
            minimumAmount: LNurlw.min_withdrawable,
            maximumAmount: LNurlw.max_withdrawable,
        })
            .then(async function (res) {
                await payService.withdraw(res.paymentRequest)
                    .then(response => {
                        if (response.data) { closePayOut(); }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(function (err) {
                console.log("error = " + err);
            });
    }

    const withdrawLightningAddress = async () => {
        await payService.withdraw(lnAddress)
            .then(response => {
                if (response.data) { closePayOut(); }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const withdrawInvoice = async () => {
        await payService.withdraw(invoice)
            .then(response => {
                if (response.data) { closePayOut(); }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleModalClick = (event) => {
        event.stopPropagation(); // Stop the event from propagating to the parent container
    }

    const handleSnackbarOpen = async (event) => {
        event.stopPropagation();
        if (navigator.clipboard) {
            navigator.clipboard.writeText(LNurlw.lnurl);
            setShowToast(true);
        } else {
            console.log("Clipboard API not supported");
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={closePayOut}
            >
                {showToast &&
                    <div
                        id="toast-simple"
                        className="flex items-center justify-center fixed bottom-5 left-auto right-auto transform -translate-x-1/2 w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
                        role="alert"
                    >
                        <i className="fas fa-clipboard text-xl"></i>
                        <div className="pl-4 text-sm font-normal">Invoice copied to the clipboard.</div>
                    </div>
                }
                <div
                    className="relative w-auto my-6 mx-10_100 max-w-3xl"
                    onClick={handleModalClick}
                >
                    {/*content*/}
                    <div className="overflow-y-auto border-0 lg:px-8 max-h-80vh min-w-20vw max-w-90vw rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="absolute top-0 right-0 mt-4 mr-4">
                            <button className="text-blueGray-500 background-transparent font-bold uppercase p-2 text-base outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={closePayOut}
                            >
                                X
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <span className="text-base font-bold text-center w-full font-normal mb-10 justify-center flex">
                                {`Withdraw up to ${amountText} sats (~ $${price})`}
                            </span>
                            <div className="my-6 text-center flex">
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                                <div className="px-2 text-center">
                                    <span className="pr-4 text-base">Send to Lightning Address</span>
                                    <i className="fas fa-info mr-2 text-base"></i>
                                </div>
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                            </div>
                            <div className="relative w-full mb-3">
                                <input
                                    type="email"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder='your@lightning.address'
                                    value={lnAddress}
                                    onChange={(e) => setLnAddress(e.target.value)}
                                />
                            </div>
                            <div className="my-6 text-center flex">
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                                <div className="px-2 text-center">
                                    <span className="pr-4 text-base">OR scan this QR code</span>
                                    <i className="fas fa-info mr-2 text-base"></i>
                                </div>
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                            </div>
                            {LNurlw !== null && LNurlw.lnurl ? (
                                <div className='flex flex-col justify-center items-center'>
                                    <canvas
                                        className='cursor-pointer justify-center flex my-2 h-auto-important max-w-full'
                                        onClick={handleSnackbarOpen}
                                        ref={canvasRef}
                                    />
                                    <span
                                        onClick={handleSnackbarOpen}
                                        className="text-sm text-center w-full font-italic justify-center flex cursor-pointer"
                                    >
                                        {LNurlw.lnurl.substring(0, 15)}...
                                        {LNurlw.lnurl.substring(LNurlw.lnurl.length - 15)}
                                    </span>
                                </div>
                            ) : (
                                <div className="text-center h-auto-important max-w-full w-400-px flex justify-center items-center">
                                    <div role="status">
                                        <svg
                                            aria-hidden="true"
                                            className="inline w-16 h-16 m-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>

                            )}
                            <div className="my-6 text-center flex">
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                                <div className="px-2 text-center">
                                    <span className="pr-4 text-base">OR paste an invoice</span>
                                    <i className="fas fa-info mr-2 text-base"></i>
                                </div>
                                <div className="self-center flex-grow h-0 my-2 border border-solid border-blueGray-100"></div>
                            </div>
                            <div className="relative w-full mb-3">
                                <input
                                    type="email"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder='Paste your invoice here'
                                    value={invoice}
                                    onChange={(e) => setInvoice(e.target.value)}
                                />
                            </div>
                            <>

                                <button
                                    type="button"
                                    onClick={manageWithdraw}
                                    className={`w-full bg-sky-500 mt-10 hover:bg-sky-600 active:bg-sky-600 uppercase text-white font-bold hover:shadow-lg shadow text-base px-4 py-2 rounded outline-none focus:outline-none my-2 ease-linear transition-all duration-150 ${!enableButton ? 'opacity-50 cursor-default' : 'hover:shadow-lg'
                                        }`}
                                    disabled={!enableButton}
                                    style={{ pointerEvents: !enableButton ? 'none' : 'auto' }}
                                >
                                    Withdraw
                                </button>
                                {!isWebln && !isMobile &&
                                    <a
                                        href="https://kollider.xyz/wallet"
                                        className="text-sky-500 text-xs font-italic justify-center flex"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fas fa-info mr-2 text-xs"></i>
                                        Learn how to get paid from your browser
                                    </a>
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default PayOut;