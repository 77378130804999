import React, { useState, useEffect, useCallback } from 'react';
import UserCard from "./UserCard.js"
import userService from 'services/user.service.js';
import { useLocation, useNavigate } from 'react-router-dom';

function UserList({ paymentReceived }) {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedComponent = location?.state?.selectedComponent;
    const [users, setUsers] = useState(null);
    const [isCreator, setIsCreator] = useState(false);
    const [showRenew, setShowRenew] = useState(false);

    useEffect(() => {
        switch (selectedComponent) {
            case "numFans":
                setShowRenew(true);
                setIsCreator(true);
                break;
            case "numFollowers":
                setShowRenew(false);
                setIsCreator(true);
                break;
            case "numFollowing":
                setShowRenew(false);
                setIsCreator(false);
                break;
            case "numSubscriptions":
                setShowRenew(true);
                setIsCreator(false);
                break;
            default:
                navigate("/home");
                break;
        }
        if (selectedComponent) {
            getUsers(selectedComponent);
        }
    }, [selectedComponent]);

    const getUsers = useCallback((selectedComponent) => { // add useCallback for optimization
        userService.getUsers(selectedComponent)
            .then(response => {
                setUsers(response.data.users);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (paymentReceived) {
            window.location.reload(true);
        }
    }, [paymentReceived]);

    const goBack = () => {
        navigate("/dashboard");
    }

    return (
        <div className="relative container mx-auto flex flex-col items-center pt-4 md:pt-16 w-full min-h-screen">
            <div className="relative flex flex-col items-center pt-4 w-full">
                <div className="absolute top-0 left-0 mt-4 mr-4">
                    <button className="text-sky-500 background-transparent font-bold uppercase px-6 py-2 text-base outline-none focus:outline-none ease-linear transition-all duration-150"
                        onClick={goBack}
                        type="button"
                    >
                        <i className="fas fa-chevron-left"></i> Back
                    </button>
                </div>
                <div className="text-2xl font-medium dark:text-white text-base leading-normal mb-8 font-bold">
                    <h2>{selectedComponent?.slice(3)}</h2> {/* add the title */}
                </div>
                {users?.map(user => (
                    <div className="px-4 w-full lg:w-10/12" key={user.username}>
                        <UserCard userInfo={user} showRenew={showRenew} isCreator={isCreator} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserList;